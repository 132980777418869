<app-base-page [centered]="true" title="Mijn profiel" class="profile">
    <mat-spinner *ngIf="!patient" class="mx-auto my-32" diameter="30"></mat-spinner>
    <div class="flex flex-col gap-6" *ngIf="patient">
        <app-section *ngFor="let section of sections" [title]="section.title">
            <div
                *ngFor="let inner of section.innerSections; let last = last"
                class="border rounded-xl border-grey-2 p-6 flex flex-col gap-6"
                [ngClass]="{'mb-6' : !last}"
            >
                <div class="flex justify-between">
                    <div *ngIf="inner.title.icon else labelOnly" class="border border-grey-2 rounded-full py-1 px-2">
                        <fa-icon class="text-deepsea-1 mr-2" [icon]="inner.title.icon"></fa-icon>
                        {{ inner.title.label }}
                    </div>
                    <ng-template #labelOnly>
                        <h4>{{ inner.title.label }}</h4>
                    </ng-template>

                    <div *ngIf="inner.action"
                         (click)="inner.action(inner)"
                         class="text-deepsea-1 hover:underline cursor-pointer"
                    >
                        <fa-icon class="mr-2" [icon]="['far', 'edit']"></fa-icon>
                        Bewerken
                    </div>
                </div>

                <div *ngFor="let input of inner.inputs" class="text-sm flex flex-col">
                    <div class="font-bold mb-2">
                        {{input.label}}
                    </div>
                    <div *ngIf="input.formGroupName && patient[input.formGroupName][input.key] else singleKey">
                        {{ getValue(input, patient[input.formGroupName][input.key]) }}
                    </div>
                    <ng-template #singleKey>
                        <div *ngIf="patient[input.key] else infoWarning">
                            {{ getValue(input, patient[input.key]) }}
                        </div>
                    </ng-template>
                    <ng-template #infoWarning>
                        <app-chip class="self-start" [color]="'red'">
                            <fa-icon class="me-2" [icon]="['fas', 'exclamation-circle']"></fa-icon>
                            {{ 'Vul je ' + input.label + ' in' }}.
                        </app-chip>
                    </ng-template>
                </div>
            </div>
        </app-section>
    </div>
</app-base-page>
