import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {formatDate} from "@angular/common";
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";
import {createMask} from "@ngneat/input-mask";

@Component({
  selector: 'app-form-dob-input',
  templateUrl: './form-dob-input.component.html',
  styleUrls: ['./form-dob-input.component.scss']
})
export class FormDobInputComponent extends BaseFormInputComponent implements OnInit {
  private dateToken = 'dd - MM - yyyy';
  dateInputMask = createMask<Date>({
    alias: 'datetime',
    inputFormat: this.dateToken,
    placeholder: 'DD - MM - YYYY',
    parser: (value: string) => {
      const values = value.split(' - ');
      const year = +values[2];
      const month = +values[1];
      const date = +values[0];

      const format = `${month}-${date}-${year}`;

      // const dob = new Date(year, month - 1, date);
      return format as any;
    },
    formatter: (value: string) => {
      const values = value.split('-');
      const date = +values[2].substr(0, 2); // Remove timestamp at the end
      const month = +values[1] - 1;
      const year = +values[0];
      const dob = new Date(year, month, date);
      return formatDate(dob, this.dateToken, 'nl-NL');
    },
  });

  constructor(override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
  }

}
