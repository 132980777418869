import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentItemComponent } from './treatment-item.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";



@NgModule({
    declarations: [
        TreatmentItemComponent
    ],
    exports: [
        TreatmentItemComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule
    ]
})
export class TreatmentItemModule { }
