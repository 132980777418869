import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDialogComponent } from './form-dialog.component';
import {BaseDialogModule} from "@components/organisms/dialogs/base-dialog/base-dialog.module";
import {MatButtonModule} from "@angular/material/button";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {ReactiveFormsModule} from "@angular/forms";
import {DynamicFormModule} from "@components/templates/dynamic-form/dynamic-form.module";



@NgModule({
  declarations: [
    FormDialogComponent
  ],
  imports: [
    CommonModule,
    BaseDialogModule,
    MatButtonModule,
    BaseButtonModule,
    ReactiveFormsModule,
    DynamicFormModule
  ]
})
export class FormDialogModule { }
