import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar.component';
import {MatDividerModule} from "@angular/material/divider";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RouterLink, RouterLinkActive} from "@angular/router";



@NgModule({
    declarations: [
        TopbarComponent
    ],
    exports: [
        TopbarComponent
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        FontAwesomeModule,
        RouterLink,
        RouterLinkActive
    ]
})
export class TopbarModule { }
