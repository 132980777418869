import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {FormTextInputComponent} from "./form-text-input.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {MatInputModule} from "@angular/material/input";



@NgModule({
  declarations: [
    FormTextInputComponent
  ],
  exports: [
    FormTextInputComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormErrorModule,
        FontAwesomeModule,
        MatInputModule
    ]
})
export class FormTextInputModule { }
