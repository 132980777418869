<app-base-page>
    <div class="grid lg:grid-cols-12">
        <app-section class="login lg:col-start-2 lg:col-end-12 self-center">
            <div class="flex flex-col text-body">
                <div class="w-full">
                    <img class="mb-6 w-8 h-8" src="assets/eye.svg" />
                </div>
                <ng-container *ngIf="currentStep">
                    <ng-container *ngComponentOutlet="
                        currentStep.component;
                        inputs: currentStep.inputs;
                    "/>
                </ng-container>
            </div>

            <mat-divider class="my-6 mx-0"></mat-divider>

            <span class="text-grey-3">
                <fa-icon class="me-2" [icon]="['fas', 'lock']"></fa-icon>
                Uw persoonsgegevens zijn beveiligd conform geldende wet- en regelgeving.
            </span>
        </app-section>
    </div>
</app-base-page>
