import {BaseFormInput, FormInputType, RemarksInput, SelectInput, SingleRadioInput} from "@util/types/interfaces";
import {eyeCorrectionDropdown, YesNoDropdown} from "@util/types/dropdowns";

export enum SectionTitles {
    GeneralHealth = 'Jouw algemene gezondheid',
    EyeCorrection = 'Jouw oog correctie',
    EyeHealth = 'Jouw oog gezondheid',
    License = 'Jouw rijbewijs',
    ZDNumber = 'ZD Nummer',
    Complaint = 'Jouw oogklacht',
    Other = 'Overig'
}

// General Health
export const explanation = new BaseFormInput('Kun je een toelichting geven over jouw oogklacht(en)?', 'explanation', FormInputType.Textarea);
const isHealthy = new SingleRadioInput('Ben je gezond?', 'isHealthy', YesNoDropdown);
const healthRemarks = new RemarksInput('healthRemarks', {'form.isHealthy': 'no'});
const hasHadTreatmentForDiabetes = new SingleRadioInput('Ben je bekend met of in behandeling voor diabetes mellitus (suikerziekte)?', 'hasHadTreatmentForDiabetes', YesNoDropdown);
const hasHadTreatmentForDiabetesRemarks = new RemarksInput('hasHadTreatmentForDiabetesRemarks', {'form.hasHadTreatmentForDiabetes': 'yes'});
const hasHadTreatmentForDiabetesRetino = new SingleRadioInput('Ben je bekend met of in behandeling voor diabetische retinopathie (netvliesafwijkingen door suikerziekte)?', 'hasHadTreatmentForDiabetesRetino', YesNoDropdown);
const hasHadTreatmentForDiabetesRetinoRemarks = new RemarksInput('hasHadTreatmentForDiabetesRetinoRemarks', {'form.hasHadTreatmentForDiabetesRetino': 'yes'});

const hasHadTreatmentForStroke = new SingleRadioInput('Ben je bekend met of in behandeling voor een hersenbloeding/herseninfarct (CVA/TIA)?', 'hasHadTreatmentForStroke', YesNoDropdown);
const hasHadTreatmentForStrokeRemarks = new RemarksInput('hasHadTreatmentForStrokeRemarks', {'form.hasHadTreatmentForStroke': 'yes'});
const hasHadTreatmentForBrainTumor = new SingleRadioInput('Ben je bekend met of in behandeling voor een hersentumor?', 'hasHadTreatmentForBrainTumor', YesNoDropdown);
const hasHadTreatmentForBrainTumorRemarks = new RemarksInput('hasHadTreatmentForBrainTumorRemarks', {'form.hasHadTreatmentForBrainTumor': 'yes'});
const hasHadTreatmentForHypertension = new SingleRadioInput('Ben je bekend met of in behandeling voor hoge bloeddruk (hypertensie)?', 'hasHadTreatmentForHypertension', YesNoDropdown);
const hasHadTreatmentForHypertensionRemarks = new RemarksInput('hasHadTreatmentForHypertensionRemarks', {'form.hasHadTreatmentForHypertension': 'yes'});
const hasHadTreatmentForSleepApnea = new SingleRadioInput('Ben je bekend met of in behandeling voor slaapapneu?', 'hasHadTreatmentForSleepApnea', YesNoDropdown);
const hasHadTreatmentForSleepApneaRemarks = new RemarksInput('hasHadTreatmentForSleepApneaRemarks', {'form.hasHadTreatmentForSleepApnea': 'yes'});
const hasSmoked = new SingleRadioInput('Rook je of heb je in het verleden gerookt?', 'hasSmoked', YesNoDropdown);

// Eye Correction
const wearsGlasses = new SingleRadioInput('Draag je een bril?', 'wearsGlasses', YesNoDropdown);
const wearsReadingGlasses = new SingleRadioInput('Draag je een leesbril?', 'wearsReadingGlasses', YesNoDropdown, 'horizontal', null, {'form.wearsGlasses': 'yes'}, true);
const farSightedness = new SingleRadioInput('Draagt je een vertebril?', 'farSightedness', YesNoDropdown, 'horizontal', null, {'form.wearsGlasses': 'yes'}, true);
const multiFocalGlasses = new SingleRadioInput('Draagt je een multifocale bril?', 'multiFocalGlasses', YesNoDropdown, 'horizontal', null, {'form.wearsGlasses': 'yes'}, true);
const wearsGlassesRemarks = new RemarksInput('wearsGlassesRemarks', {'form.wearsGlasses': 'yes'}, true);

const wearsContactLenses = new SingleRadioInput('Draag je contactlenzen?', 'wearsContactLenses', YesNoDropdown);
const wearsContactLensesRemarks = new RemarksInput('wearsContactLensesRemarks', {'form.wearsContactLenses': 'yes'}, true);
const wearsSoftLenses = new SingleRadioInput('Draag je zachte lenzen?', 'wearsSoftLenses', YesNoDropdown, 'horizontal', null, {'form.wearsContactLenses': 'yes'}, true);
const wearsHardLenses = new SingleRadioInput('Draag je harde lenzen?', 'wearsHardLenses', YesNoDropdown, 'horizontal', null, {'form.wearsContactLenses': 'yes'}, true);
const wearsMultifocalLenses = new SingleRadioInput('Draag je multifocale lenzen?', 'wearsMultifocalLenses', YesNoDropdown, 'horizontal', null, {'form.wearsContactLenses': 'yes'}, true);

const wearsNightLenses = new SingleRadioInput('Gebruik je nachtlenzen?', 'wearsNightLenses', YesNoDropdown);
const wearsNightLensesRemarks = new RemarksInput('wearsNightLensesRemarks', {'form.wearsNightLenses': 'yes'});

const eyeCorrectionType = new SingleRadioInput('Heb je een plus of min sterkte?', 'eyeCorrectionType', YesNoDropdown);
const eyeCorrectionValues = new SingleRadioInput('Weet je de sterkte van je bril of contactlenzen?', 'eyeCorrectionValues', YesNoDropdown, 'horizontal', null, {'form.eyeCorrectionType': 'yes'}, true);
const eyeCorrectionStrength = new SelectInput('Welke oogsterkte heb je ongeveer (sterkte van de bril of contactlenzen)?', 'eyeCorrectionStrength', eyeCorrectionDropdown, false, null, {'form.eyeCorrectionValues': 'yes'}, true, true);

// Eye Health
const hasHadLaserTreatment = new SingleRadioInput('Heb je ooit een laserbehandeling aan de ogen gehad?', 'hasHadLaserTreatment', YesNoDropdown);
const hasHadLaserTreatmentRemarks = new RemarksInput('hasHadLaserTreatmentRemarks', {'form.hasHadLaserTreatment': 'yes'});
const hasHadTreatmentForRetinalAbnormalities = new SingleRadioInput('Ben je bekend met of in behandeling voor netvliesafwijkingen ivm suikerziekte (diabetische retinopathie)?', 'hasHadTreatmentForRetinalAbnormalities', YesNoDropdown);
const hasHadTreatmentForRetinalAbnormalitiesRemarks = new RemarksInput('hasHadTreatmentForRetinalAbnormalitiesRemarks', {'form.hasHadTreatmentForRetinalAbnormalities': 'yes'});
const hasHadTreatmentForGlaucoma = new SingleRadioInput('Ben je bekend met of in behandeling voor glaucoom (hoge oogdruk)?', 'hasHadTreatmentForGlaucoma', YesNoDropdown);
const hasHadTreatmentForGlaucomaRemarks = new RemarksInput('hasHadTreatmentForGlaucomaRemarks', {'form.hasHadTreatmentForGlaucoma': 'yes'});
const hasHadTreatmentForCataract = new SingleRadioInput('Ben je bekend met of in behandeling voor cataract (staar)?', 'hasHadTreatmentForCataract', YesNoDropdown);
const hasHadTreatmentForCataractRemarks = new RemarksInput('hasHadTreatmentForCataractRemarks', {'form.hasHadTreatmentForCataract': 'yes'});
const hasHadTreatmentForMaculaDegeneration = new SingleRadioInput('Ben je bekend met of in behandeling voor macula degeneratie?', 'hasHadTreatmentForMaculaDegeneration', YesNoDropdown);
const hasHadTreatmentForMaculaDegenerationRemarks = new RemarksInput('hasHadTreatmentForMaculaDegenerationRemarks', {'form.hasHadTreatmentForMaculaDegeneration': 'yes'});
const hasHadTreatmentForLazyEye = new SingleRadioInput('Ben je bekend met of in behandeling voor amblyopie (lui oog)?', 'hasHadTreatmentForLazyEye', YesNoDropdown);
const hasHadTreatmentForLazyEyeRemarks = new RemarksInput('hasHadTreatmentForLazyEyeRemarks', {'form.hasHadTreatmentForLazyEye': 'yes'});
const hasHadTreatmentForDryEyes = new SingleRadioInput('Ben je bekend met of in behandeling voor droge ogen?', 'hasHadTreatmentForDryEyes', YesNoDropdown);
const hasHadTreatmentForDryEyesRemarks = new RemarksInput('hasHadTreatmentForDryEyesRemarks', {'form.hasHadTreatmentForDryEyes': 'yes'});
const hasHadTreatmentForOtherReason = new SingleRadioInput('Ben je nu of ooit in het verleden onder behandeling geweest bij een oogarts voor een andere oorzaak dan bovenstaande?', 'hasHadTreatmentForOtherReason', YesNoDropdown);
const hasHadTreatmentForOtherReasonRemarks = new RemarksInput('hasHadTreatmentForOtherReasonRemarks', {'form.hasHadTreatmentForOtherReason': 'yes'});
const hasHadTreatmentForAllergies = new SingleRadioInput('Ben je bekend met of in behandeling voor een allergie?', 'hasHadTreatmentForAllergies', YesNoDropdown);
const hasHadTreatmentForAllergiesRemarks = new RemarksInput('hasHadTreatmentForAllergiesRemarks', {'form.hasHadTreatmentForAllergies': 'yes'});
const hasHadTreatmentForHayFever = new SingleRadioInput('Ben je bekend met of in behandeling voor hooikoorts?', 'hasHadTreatmentForHayFever', YesNoDropdown);
const hasHadTreatmentForHayFeverRemarks = new RemarksInput('hasHadTreatmentForHayFeverRemarks', {'form.hasHadTreatmentForHayFever': 'yes'});
const hasHadTreatmentForAbnormalThyroid = new SingleRadioInput('Ben je bekend met of in behandeling voor een afwijkende schildklier?', 'hasHadTreatmentForAbnormalThyroid', YesNoDropdown);
const hasHadTreatmentForAbnormalThyroidRemarks = new RemarksInput('hasHadTreatmentForAbnormalThyroidRemarks', {'form.hasHadTreatmentForAbnormalThyroid': 'yes'});
const hasHadTreatmentForRheumatism = new SingleRadioInput('Ben je bekend met of in behandeling voor reuma?', 'hasHadTreatmentForRheumatism', YesNoDropdown);
const hasHadTreatmentForRheumatismRemarks = new RemarksInput('hasHadTreatmentForRheumatismRemarks', {'form.hasHadTreatmentForRheumatism': 'yes'});
const hasHadTreatmentForNeurologicalDisorder = new SingleRadioInput('Ben je bekend met of in behandeling voor een neurologische aandoening?', 'hasHadTreatmentForNeurologicalDisorder', YesNoDropdown);
const hasHadTreatmentForNeurologicalDisorderRemarks = new RemarksInput('hasHadTreatmentForNeurologicalDisorderRemarks', {'form.hasHadTreatmentForNeurologicalDisorder': 'yes'});
const hasHadEyeLidSurgeries = new SingleRadioInput('Heb je oog een ooglid-operatie gehad?', 'hasHadEyeLidSurgeries', YesNoDropdown);
const hasHadEyeLidSurgeriesRemarks = new RemarksInput('hasHadEyeLidSurgeriesRemarks', {'form.hasHadEyeLidSurgeries': 'yes'});
const canEyesFullyClose = new SingleRadioInput('Heb je het gevoel dat je ogen niet helemaal dicht gaan als je ze sluit?', 'canEyesFullyClose', YesNoDropdown);
const canEyesFullyCloseRemarks = new RemarksInput('canEyesFullyCloseRemarks', {'form.canEyesFullyClose': 'yes'});
const hasHadCorneaInflammation = new SingleRadioInput('Heb je ooit een hoornvliesontsteking gehad?', 'hasHadCorneaInflammation', YesNoDropdown);
const hasHadCorneaInflammationRemarks = new RemarksInput('hasHadCorneaInflammationRemarks', {'form.hasHadCorneaInflammation': 'yes'});
const hasHadChemicalBurn = new SingleRadioInput('Heb je ooit een chemische stoffen in het oog gehad (bijv bleek)?', 'hasHadChemicalBurn', YesNoDropdown);
const hasHadChemicalBurnRemarks = new RemarksInput('hasHadChemicalBurnRemarks', {'form.hasHadChemicalBurn': 'yes'});
const hasHadTreatmentForRetinalDetachment = new SingleRadioInput('Ben je bekend met of in behandeling voor een netvliesscheur of netvliesloslating?', 'hasHadTreatmentForRetinalDetachment', YesNoDropdown);
const hasHadTreatmentForRetinalDetachmentRemarks = new RemarksInput('hasHadTreatmentForRetinalDetachmentRemarks', {'form.hasHadTreatmentForRetinalDetachment': 'yes'});
const hasFamilyMembersWithRetinalDetachment = new SingleRadioInput('Zijn er familieleden van je bekend met een netvliesloslating?', 'hasFamilyMembersWithRetinalDetachment', YesNoDropdown);
const hasFamilyMembersWithRetinalDetachmentRemarks = new RemarksInput('hasFamilyMembersWithRetinalDetachmentRemarks', {'form.hasFamilyMembersWithRetinalDetachment': 'yes'});
const hasHadCataractSurgery = new SingleRadioInput('Heb je ooit een staaroperatie of lensvervanging gehad?', 'hasHadCataractSurgery', YesNoDropdown);
const hasHadCataractSurgeryRemarks = new RemarksInput('hasHadCataractSurgeryRemarks', {'form.hasHadCataractSurgery': 'yes'});
// Other
export const additionalInformation = new BaseFormInput('Heb je nog aanvullende informatie?', 'additionalInformation', FormInputType.Textarea, null, null, null, false, false);

// Rijges
export const rijgesGeneralHealthQuestions = [
    isHealthy,
    healthRemarks,
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForStroke,
    hasHadTreatmentForStrokeRemarks,
    hasHadTreatmentForBrainTumor,
    hasHadTreatmentForBrainTumorRemarks,
];

export const rijgesEyeCorrectionQuestions = [
    wearsGlasses,
    wearsContactLenses,
    wearsNightLenses,
];

export const rijgesEyeHealthQuestions = [
    hasHadTreatmentForDiabetesRetino,
    hasHadTreatmentForDiabetesRetinoRemarks,
    hasHadTreatmentForGlaucoma,
    hasHadTreatmentForGlaucomaRemarks,
    hasHadTreatmentForCataract,
    hasHadTreatmentForCataractRemarks,
    hasHadTreatmentForMaculaDegeneration,
    hasHadTreatmentForMaculaDegenerationRemarks,
    hasHadTreatmentForLazyEye,
    hasHadTreatmentForLazyEyeRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks,
];


// Optometric Examination
export const optometricExaminationGeneralHealthQuestions = [
    isHealthy,
    healthRemarks,
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForHypertension,
    hasHadTreatmentForHypertensionRemarks,
    hasHadTreatmentForStroke,
    hasHadTreatmentForStrokeRemarks,
    hasHadTreatmentForBrainTumor,
    hasHadTreatmentForBrainTumorRemarks,
    hasHadTreatmentForSleepApnea,
    hasHadTreatmentForSleepApneaRemarks,
    hasSmoked,
];

export const optometricExaminationCorrectionQuestions = [
    wearsGlasses,
    wearsReadingGlasses,
    farSightedness,
    multiFocalGlasses,
    wearsGlassesRemarks,
    wearsContactLenses,
    wearsSoftLenses,
    wearsHardLenses,
    wearsMultifocalLenses,
    wearsContactLensesRemarks,
    wearsNightLenses,
    wearsNightLensesRemarks
];

export const optometricExaminationEyeHealthQuestions = [
    hasHadLaserTreatment,
    hasHadLaserTreatmentRemarks,
    hasHadTreatmentForRetinalAbnormalities,
    hasHadTreatmentForRetinalAbnormalitiesRemarks,
    hasHadTreatmentForGlaucoma,
    hasHadTreatmentForGlaucomaRemarks,
    hasHadTreatmentForCataract,
    hasHadTreatmentForCataractRemarks,
    hasHadTreatmentForMaculaDegeneration,
    hasHadTreatmentForMaculaDegenerationRemarks,
    hasHadTreatmentForLazyEye,
    hasHadTreatmentForLazyEyeRemarks,
    hasHadTreatmentForDryEyes,
    hasHadTreatmentForDryEyesRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks,
];

// Glaucoma
export const glaucomaGeneralHealthQuestions = [
    isHealthy,
    healthRemarks,
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForHypertension,
    hasHadTreatmentForHypertensionRemarks,
    hasHadTreatmentForStroke,
    hasHadTreatmentForStrokeRemarks,
    hasHadTreatmentForBrainTumor,
    hasHadTreatmentForBrainTumorRemarks,
    hasHadTreatmentForSleepApnea,
    hasHadTreatmentForSleepApneaRemarks,
    hasSmoked,
];

export const glaucomaCorrectionQuestions = [
    wearsGlasses,
    wearsReadingGlasses,
    farSightedness,
    multiFocalGlasses,
    wearsGlassesRemarks,
    wearsContactLenses,
    wearsSoftLenses,
    wearsHardLenses,
    wearsMultifocalLenses,
    wearsContactLensesRemarks,
    wearsNightLenses,
    wearsNightLensesRemarks
];

export const glaucomaEyeHealthQuestions = [
    hasHadLaserTreatment,
    hasHadLaserTreatmentRemarks,
    hasHadTreatmentForRetinalAbnormalities,
    hasHadTreatmentForRetinalAbnormalitiesRemarks,
    hasHadTreatmentForGlaucoma,
    hasHadTreatmentForGlaucomaRemarks,
    hasHadTreatmentForCataract,
    hasHadTreatmentForCataractRemarks,
    hasHadTreatmentForMaculaDegeneration,
    hasHadTreatmentForMaculaDegenerationRemarks,
    hasHadTreatmentForLazyEye,
    hasHadTreatmentForLazyEyeRemarks,
    hasHadTreatmentForDryEyes,
    hasHadTreatmentForDryEyesRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks,
];

// Dry eyes
export const dryEyesGeneralHealthQuestions = [
    isHealthy,
    healthRemarks,
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForAllergies,
    hasHadTreatmentForAllergiesRemarks,
    hasHadTreatmentForHayFever,
    hasHadTreatmentForHayFeverRemarks,
    hasHadTreatmentForAbnormalThyroid,
    hasHadTreatmentForAbnormalThyroidRemarks,
    hasHadTreatmentForRheumatism,
    hasHadTreatmentForRheumatismRemarks,
    hasHadTreatmentForNeurologicalDisorder,
    hasHadTreatmentForNeurologicalDisorderRemarks,
    hasSmoked,
]

export const dryEyesCorrectionQuestions = [
    wearsGlasses,
    wearsContactLenses,
    wearsSoftLenses,
    wearsHardLenses,
    wearsContactLensesRemarks,
];

export const dryEyesEyeHealthQuestions = [
    hasHadLaserTreatment,
    hasHadLaserTreatmentRemarks,
    hasHadEyeLidSurgeries,
    hasHadEyeLidSurgeriesRemarks,
    canEyesFullyClose,
    canEyesFullyCloseRemarks,
    hasHadCorneaInflammation,
    hasHadCorneaInflammationRemarks,
    hasHadChemicalBurn,
    hasHadChemicalBurnRemarks,
    hasHadTreatmentForGlaucoma,
    hasHadTreatmentForGlaucomaRemarks,
    hasHadTreatmentForDryEyes,
    hasHadTreatmentForDryEyesRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks,
];

// Visual Impairment
export const viGeneralHealthQuestions = [
    isHealthy,
    healthRemarks,
    hasHadTreatmentForDiabetes,
    hasHadTreatmentForDiabetesRemarks,
    hasHadTreatmentForHypertension,
    hasHadTreatmentForHypertensionRemarks,
    hasHadTreatmentForStroke,
    hasHadTreatmentForStrokeRemarks
];

export const viCorrectionQuestions = [
    wearsGlasses,
    wearsReadingGlasses,
    farSightedness,
    multiFocalGlasses,
    wearsGlassesRemarks,
    wearsContactLenses,
    wearsSoftLenses,
    wearsHardLenses,
    wearsMultifocalLenses,
    wearsContactLensesRemarks,
    wearsNightLenses,
    wearsNightLensesRemarks
];

export const viEyeHealthQuestions = [
    hasHadLaserTreatment,
    hasHadLaserTreatmentRemarks,
    hasHadTreatmentForRetinalAbnormalities,
    hasHadTreatmentForRetinalAbnormalitiesRemarks,
    hasHadTreatmentForGlaucoma,
    hasHadTreatmentForGlaucomaRemarks,
    hasHadTreatmentForCataract,
    hasHadTreatmentForCataractRemarks,
    hasHadTreatmentForMaculaDegeneration,
    hasHadTreatmentForMaculaDegenerationRemarks,
    hasHadTreatmentForLazyEye,
    hasHadTreatmentForLazyEyeRemarks,
    hasHadTreatmentForDryEyes,
    hasHadTreatmentForDryEyesRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks,
];

// Spots and Flashes
export const spotsAndFlashesCorrectionQuestions = [
    eyeCorrectionType,
    eyeCorrectionValues,
    eyeCorrectionStrength
]

export const spotsAndFlashesEyeHealthQuestions = [
    hasHadTreatmentForRetinalDetachment,
    hasHadTreatmentForRetinalDetachmentRemarks,
    hasFamilyMembersWithRetinalDetachment,
    hasFamilyMembersWithRetinalDetachmentRemarks,
    hasHadLaserTreatment,
    hasHadLaserTreatmentRemarks,
    hasHadCataractSurgery,
    hasHadCataractSurgeryRemarks,
    hasHadTreatmentForOtherReason,
    hasHadTreatmentForOtherReasonRemarks
];
