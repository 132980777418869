import {Directive, ElementRef, Input, OnInit, ViewContainerRef} from '@angular/core';
// import {ConsultFormQuestionBase} from "@util/forms/interfaces/consult.interface";
// import {error} from "@angular/compiler-cli/src/transformers/util";

@Directive({
  selector: '[formErrorContainer]'
})
export class FormErrorContainerDirective implements OnInit {
  get questionKey(): string {
    return this._questionKey;
  }

  @Input() set questionKey(value: string) {
    this._questionKey = value;
  }
  private _questionKey: string;
  public errorMap: {key: string | number, messages: string[]}[] = [];

  get hasError(): boolean {
    return this._hasError;
  }

  @Input() set hasError(value: boolean) {
    this._hasError = value;
    if (value) {
      this.elementRef?.nativeElement?.classList?.add('hasError');
    } else {
      this.elementRef?.nativeElement?.classList?.remove('hasError');
    }
  }
  private _hasError = false;

  constructor(public vcr: ViewContainerRef, public elementRef: ElementRef) {

  }

  ngOnInit() {
  }
}
