import { Component } from '@angular/core';
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {FormBuilder} from "@angular/forms";
import {Rijges107QuestionnaireComponent} from "../rijges107-questionnaire/rijges107-questionnaire.component";
import {PatientService} from "@core/services/patient.service";
import {HotToastService} from "@ngneat/hot-toast";
import {Router} from "@angular/router";

// TODO: Seems the same as 107, should just extend that one.
@Component({
  selector: 'app-rijges095-questionnaire',
  templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
  styleUrls: ['./rijges095-questionnaire.component.scss']
})
export class Rijges095QuestionnaireComponent extends Rijges107QuestionnaireComponent {
  constructor(
      override questionnairesService: QuestionnaireService,
      override formBuilder: FormBuilder,
      override patientService: PatientService,
      override toastService: HotToastService,
      override router: Router,
  ) {
    super(questionnairesService, formBuilder, patientService, toastService, router);
  }
}
