<app-base-dialog [title]="data.section.title.label">
    <form [formGroup]="form" class="flex flex-col gap-6" (ngSubmit)="save()">
        <app-form-inputs
            *ngFor="let input of data.section.inputs"
            [input]="$any(input)"
            [formGroup]="form"
        ></app-form-inputs>

        <button hidden #hiddenButton></button>
    </form>

    <div class="flex flex-1 justify-between" dialog-actions>
        <app-button (onClick)="dialogRef.close()" color="transparent">
            Annuleren
        </app-button>
        <app-button (onClick)="hiddenButton.click()">
            <fa-icon class="mr-2" [icon]="['far', 'save']"></fa-icon>
            Opslaan
        </app-button>

        <app-form-dev-tools [form]="form"></app-form-dev-tools>
    </div>
</app-base-dialog>
