import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import {SecurityService} from "@core/services/security.service";
import {HotToastService} from "@ngneat/hot-toast";
import {openToast, ToastType} from "@util/helpers/toastHelper";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private securityService: SecurityService,
              private router: Router,
              private hotToast: HotToastService,) {
  }

  canActivate(): boolean {
    return this.checkAuth();
  }

  canActivateChild(): boolean {
    return this.checkAuth();
  }

  canLoad(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.securityService.accessToken) {
      return true;
    } else {
      openToast(this.hotToast, 'U bent niet (meer) ingelogd!', ToastType.Error);
      this.router.navigate(['/login']);
      return false;
    }
  }
}