<div class="card p-6 lg:p-8 self-start">
    <div class="card__header flex gap-6 mb-4">
        <ng-content select="[header]"></ng-content>
    </div>

    <ng-content></ng-content>

    <div class="card__body flex flex-col gap-4" [ngClass]="{'card__body--scroll' : scroll}">
        <ng-content select="[body]"></ng-content>
    </div>

    <div class="card__footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
