<div class="flex gap-6 items-center flex-wrap">
    <img *ngIf="locationLogo" class="max-w-48" [src]="locationLogo">

    <div class="flex flex-col gap-2">
        <span class="card__title">{{locationService.activeLocation.name}}</span>
        <span class="card__address">
            {{ parseAddress(locationService.activeLocation.address) }}
        </span>
    </div>
</div>
