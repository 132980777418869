<button
    class="w-full item text-sm py-2 px-5 flex items-center lg:py-4 lg:px-8"
    [class.item--selected]="treatment.name === selectedTreatment?.name"
    (click)="selectTreatment.emit(treatment)"
>
    <div
        class="checkbox mr-4"
        [class.checkbox--selected]="treatment.name === selectedTreatment?.name"
    ></div>

    <span>{{treatment.prettyName}}</span>
    <span class="ml-auto">{{priceNotation(treatment.price)}}</span>
</button>
