import { Component } from '@angular/core';
import {BaseQuestionnaireTypeComponent, Section} from "../base-questionnaire-type/base-questionnaire-type.component";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {FormBuilder} from "@angular/forms";
import {BaseFormInput, FormInputType, SingleRadioInput} from "@util/types/interfaces";
import {
  additionalInformation, explanation,
  optometricExaminationCorrectionQuestions, optometricExaminationEyeHealthQuestions,
  optometricExaminationGeneralHealthQuestions,
  SectionTitles
} from "../sharedQuestions";
import {YesNoDropdown} from "@util/types/dropdowns";
import {PatientService} from "@core/services/patient.service";
import {HotToastService} from "@ngneat/hot-toast";
import {Router} from "@angular/router";

@Component({
  selector: 'app-optometric-examination-questionnaire',
  templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
  styleUrls: ['./optometric-examination-questionnaire.component.scss']
})
export class OptometricExaminationQuestionnaireComponent extends BaseQuestionnaireTypeComponent {
  constructor(
      override questionnairesService: QuestionnaireService,
      override formBuilder: FormBuilder,
      override patientService: PatientService,
      override toastService: HotToastService,
      override router: Router,
  ) {
    const sections: Section[] = [
      {
        title: SectionTitles.Complaint,
        inputs: [
          explanation,
        ]
      },
      {
        title: SectionTitles.GeneralHealth,
        inputs: optometricExaminationGeneralHealthQuestions
      },
      {
        title: SectionTitles.EyeCorrection,
        inputs: optometricExaminationCorrectionQuestions
      },
      {
        title: SectionTitles.EyeHealth,
        inputs: optometricExaminationEyeHealthQuestions
      },
      {
        title: SectionTitles.Other,
        inputs: [additionalInformation]
      }
    ];

    super(questionnairesService, formBuilder, patientService, sections, toastService, router);
  }
}
