import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

// TODO: Use this instead of LoginService for token!
@Injectable()
export class SecurityService {
  public userId: string;

  get accessToken(): string {
    if(!this._accessToken && localStorage.getItem('accessToken')) {
      this._accessToken = localStorage.getItem('accessToken');
    }
    return this._accessToken;
  }

  set accessToken(value: string) {
    localStorage.setItem('accessToken', value);
    this._accessToken = value;
  }
  private _accessToken = null;

  public get devOnly(): boolean {
    return environment.skipAuthentication;
  }

  constructor(
    // @Optional() public oidcSecurityService?: OidcSecurityService,
    // @Optional() private eventService?: PublicEventsService
  ) {
    // if(eventService) {
    //   this.eventService.registerForEvents()
    //     //.pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
    //     .subscribe((value) => {
    //       console.log(value);
    //     });
    // }

    if(this.devOnly) {
      if(localStorage.getItem('userId')) {
        this.userId = localStorage.getItem('userId');
      }
    }

    // else {
    //   this.oidcSecurityService.getAccessToken().subscribe(token => {
    //     this.accessToken = token;
    //   });
    // }
  }

  getAccessToken(): string {
    if (!this.devOnly) {
      if(!this.accessToken) {
        console.error("No access token available (yet)!");
        return null;
      }

      return this.accessToken;
    } else {
      throw new Error("Attempting to get access token while not in production mode");
    }
  }

  setup(ssoToken?: string): Observable<void> {
    if (!this.devOnly) {
      // return this.oidcSecurityService.checkAuth().pipe(map(((auth: LoginResponse) => {
      //   console.log(auth);
      //
      //   if (!auth.isAuthenticated) {
      //     this.authorize(ssoToken);
      //   }
      //
      //   if(auth.errorMessage) {
      //     console.error(auth.errorMessage);
      //   }
      //
      //   if(auth.accessToken) {
      //     this.accessToken = auth.accessToken;
      //   }
      //
      //   return auth;
      // })));
    }

    return new Observable<void>();
  }

  authorize(ssoToken?: string): void {
    if (!this.devOnly) {
      // this.oidcSecurityService.authorize(null, {
      //   urlHandler(url: string): any {
      //     if(ssoToken) {
      //       console.log("SSO token found... logging in user...");
      //       url += '&user_exchange_token=' + ssoToken;
      //     }
      //     window.location.replace(url);
      //   }
      // });
    }
  }

  logoff(): void {
    if (!this.devOnly) {
      // this.oidcSecurityService.logoff();
    }

    this.accessToken = null;
    this.userId = null;
    if(localStorage.getItem('userId')) {
      localStorage.removeItem('userId');
    }

    if(localStorage.getItem('accessToken')) {
      localStorage.removeItem('accessToken');
    }
  }

  // TODO: move this to an interceptor.
  getHttpRequestOptions(): object {
    if (this.devOnly) {
      if (this.userId) {
        return {
          headers: {
            'debug-principal-user-id': this.userId
          }
        };
      } else {
        return {}
      }
    }

    return {}
  }
}
