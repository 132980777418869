import {FormGroup} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {PostalcodeService} from "@core/services/postalcode.service";

// TODO: Rename make the 'get()'s more dynamic
let addressFound = false;
export async function getAddress(
    form: FormGroup,
    postalcodeService: PostalcodeService,
    addressKey = 'patient.address',
): Promise<boolean> {
    const postalCode = form.get(addressKey + '.postalCode');
    const houseNr = form.get(addressKey + '.houseNr')

    if(!addressFound) {
        if(postalCode.valid && houseNr.valid) {
            addressFound = null;

            const address = await firstValueFrom(postalcodeService.find(
                postalCode.value,
                houseNr.value,
            ));

            if (Object.keys(address).length > 0) {
                addressFound = true;
                const suffix = form.get(addressKey + '.houseNrSuffix').value;
                form.get(addressKey).patchValue(address, {emitEvent: false});
                suffix ? form.get(addressKey + '.houseNrSuffix').patchValue(suffix, {emitEvent: false}) : undefined;
                setTimeout(() => addressFound = null, 1000);
            } else {
                addressFound = false;
                form.get(addressKey + '.streetName').patchValue('', {emitEvent: false});
                form.get(addressKey + '.city').patchValue('', {emitEvent: false});
            }

            return addressFound;
        }
    }

    return false;
}
