import {Component} from '@angular/core';
import {tileLayer, latLng, MapOptions, marker} from 'leaflet';
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {HotToastService} from "@ngneat/hot-toast";
import {Router} from "@angular/router";
import {PatientService} from "@core/services/patient.service";
import {firstValueFrom} from "rxjs";
import {SecurityService} from "@core/services/security.service";
import {Patient} from "@util/types/models";
import {parseName} from "@util/helpers/name-helper";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
    public tasks = [];

    public messages = [
      // 'Rijbewijskeuring',
      // 'Oogkeuring',
    ];

    public options: MapOptions = null;

    // TODO: typing.
    public patient: Patient;
    public appointments: any[] = [];
    public tasksData: any[] = [];

    constructor(
      private hotToast: HotToastService,
      private patientService: PatientService,
      private router: Router
    ) {
        this.init();
    }

    private parseTasks() {
        for(const task of this.tasksData) {
            switch(task.type) {
                case 'check_personal_data':
                    this.tasks.push({
                        title: task.title,
                        active: true,
                        callback: () => {
                            this.router.navigate(['/mijn-profiel']);
                        }
                    });
                    break;
                case 'questionnaire':
                    this.tasks.push({
                        title: task.title,
                        active: true,
                        callback: () => {
                            this.router.navigate(['/vragenlijsten'], { queryParams: { id: task.id, appointmentId: task.appointmentId } });
                        }
                    });
                    break;
            }
        }
    }

    private async init(): Promise<void> {
      const patient = await firstValueFrom(this.patientService.getMyPatient());
      const appointments = await firstValueFrom(this.patientService.getMyAppointments());
      this.tasksData = await firstValueFrom(this.patientService.getMyTasks());
      this.parseTasks();

      this.appointments = appointments?.sort((a, b) => a.calendarEntry.start > b.calendarEntry.start ? 1 : -1) ?? [];

      if(this.appointments.length > 0) {
          this.options = {
              layers: [
                  tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', { maxZoom: 18, attribution: '...' }),
                  marker([ this.appointments[0].companyLocation.geo.coordinates[1], this.appointments[0].companyLocation.geo.coordinates[0] ])
              ],
              zoom: 15,
              center: latLng(this.appointments[0].companyLocation.geo.coordinates[1], this.appointments[0].companyLocation.geo.coordinates[0]),
          };
      }

      if(!patient) {
        openToast(this.hotToast, 'Patiëntgegevens niet gevonden!', ToastType.Error);
      }

      this.patient = patient;
    }

    getToday() {
        return new Date().toISOString();
    }

    protected readonly parseName = parseName;
}
