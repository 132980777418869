<div
    class="item flex items-center"
    [class.item--selected]="method.id === selectedMethod?.id"
    (click)="selectMethod.emit(method)"
>
    <div
        class="checkbox mr-4"
        [class.checkbox--selected]="method.id === selectedMethod?.id"
    ></div>

    <div class="flex flex-col">
        <span>{{method.longDesc}}</span>
        <span *ngIf="method.id !== 'payment_in_store'" class="item__sub">Betaal vooruit</span>
    </div>

    <span *ngIf="method.image" class="ml-auto">
        <img [src]="method.image.svg" />
    </span>
</div>
