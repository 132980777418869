import {ChangeDetectorRef, Component, OnInit, Renderer2} from '@angular/core';
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";

@Component({
  selector: 'app-form-single-checkbox-input',
  templateUrl: './form-single-checkbox-input.component.html',
  styleUrls: ['./form-single-checkbox-input.component.scss']
})
export class FormSingleCheckboxInputComponent extends BaseFormInputComponent implements OnInit {
  currentCheckedValue = null;

  constructor(private ren: Renderer2, protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }
  ngOnInit(): void {
  }

}
