import {Component, Input} from '@angular/core';
import {BaseQuestionnaireTypeComponent, Section} from "../base-questionnaire-type/base-questionnaire-type.component";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import { FormInputType } from '@util/types/interfaces';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
    additionalInformation,
    explanation,
    glaucomaCorrectionQuestions, glaucomaEyeHealthQuestions,
    glaucomaGeneralHealthQuestions,
    SectionTitles
} from "../sharedQuestions";
import {PatientService} from "@core/services/patient.service";
import {HotToastService} from "@ngneat/hot-toast";
import {Router} from "@angular/router";

@Component({
  selector: 'app-glaucoma-questionnaire',
  templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
  styleUrls: ['./glaucoma-questionnaire.component.scss']
})
export class GlaucomaQuestionnaireComponent extends BaseQuestionnaireTypeComponent {
  constructor(
      override questionnairesService: QuestionnaireService,
      override formBuilder: FormBuilder,
      override patientService: PatientService,
      override toastService: HotToastService,
      override router: Router,
  ) {
      const sections: Section[] = [
          {
              title: SectionTitles.Complaint,
              inputs: [
                  explanation
              ],
          },
          {
            title: SectionTitles.GeneralHealth,
            inputs: glaucomaGeneralHealthQuestions
          },
          {
              title: SectionTitles.EyeCorrection,
              inputs: glaucomaCorrectionQuestions
          },
          {
              title: SectionTitles.EyeHealth,
              inputs: glaucomaEyeHealthQuestions
          },
          {
              title: SectionTitles.Other,
              inputs: [additionalInformation]
          }
      ];

    super(questionnairesService, formBuilder, patientService, sections, toastService, router);
  }
}
