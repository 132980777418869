<div class="flex gap-8 flex-wrap lg:flex-nowrap">
    <app-step-card class="w-full lg:w-1/2">
        <ng-container header>
            <h2 class="card__title">Kies je betaalmethode</h2>
        </ng-container>

        <ng-container body>
            <mat-spinner diameter="20" *ngIf="!paymentMethods else methods"></mat-spinner>
            <ng-template #methods>
                <app-payment-item
                    *ngFor="let method of paymentMethods"
                    [method]="method"
                    [selectedMethod]="selectedPaymentMethod"
                    (selectMethod)="selectMethod($event)"
                ></app-payment-item>
            </ng-template>

            <p>Door verder te gaan wordt er akkoord gegaan met onze <a href="https://cms.okulus.nl/wp-content/uploads/2024/10/Algemene-Voorwaarden-Okulus.pdf">algemene voorwaarden</a> en wordt er een betalingsverplichting aangegaan.</p>

            <mat-divider class="mt-2"></mat-divider>
        </ng-container>

        <ng-container footer>
            <app-step-card-footer
                [proceedText]="proceedText"
                [canProceed]="!!selectedPaymentMethod"
                (nextStep)="createPayment()"
            ></app-step-card-footer>
        </ng-container>
    </app-step-card>

    <app-appointment-summary
            class="w-full lg:w-1/2"
    ></app-appointment-summary>
</div>
