<button
    [class.mat-mdc-icon-button--secondary]="color === 'secondary'"
  mat-icon-button
  [type]="type"
  [disabled]="disabled"
  (click)="onClick.emit($event)"
>
  <ng-content></ng-content>
</button>

