<app-step-card *ngIf="stepService.selectedExamType" class="w-full sticky top-40">
    <ng-container header>
        <span class="card__title">{{stepService.selectedExamType.prettyName}}</span>
    </ng-container>

<!--    <p class="text-body card__paragraph">-->
<!--        {{examTypeTextMap[stepService.selectedExamType.name] ?? 'Fout tijdens het ophalen van de tekst!'}}-->
<!--    </p>-->

    <p *ngIf="stepService.selectedExamType.warning" class="font-bold text-body card__paragraph mt-4" style="white-space: pre-wrap">{{stepService.selectedExamType.warning}}</p>

    <mat-divider class="my-6"></mat-divider>

    <ng-container body>
        <span class="card__title">Afspraakdetails</span>
        <div class="flex flex-col detail">
            <span class="detail__title">Locatie</span>
            <span>{{locationService.activeLocation.name}}</span>
            <span>{{parseAddress(locationService.activeLocation.address)}}</span>
            <div class="flex gap-4 font-normal mt-2">
                <a *ngIf="locationService.activeLocation.phoneNumber" class="hover:underline" [href]="'tel:' + locationService.activeLocation.phoneNumber">
                    <fa-icon class="mr-1" [icon]="['far', 'phone']"></fa-icon>
                    {{locationService.activeLocation.phoneNumber}}
                </a>
                <a *ngIf="locationService.activeLocation.email" class="hover:underline" [href]="'mailto:' + locationService.activeLocation.email">
                    <fa-icon class="mr-1" [icon]="['far', 'envelope']"></fa-icon>
                    {{locationService.activeLocation.email}}
                </a>
            </div>
        </div>

        <div class="flex flex-col detail">
            <span class="detail__title">Duur onderzoek</span>
            <span>{{stepService.selectedExamType.duration}} minuten</span>
        </div>

        <div class="flex flex-col detail">
            <span class="detail__title">Kosten</span>
            <span>{{priceNotation(stepService.selectedExamType.price)}}</span>
        </div>

        <div class="flex flex-col detail" *ngIf="appointmentService.selectedDate">
            <span class="detail__title">Datum</span>
            <span>{{appointmentService.selectedDate | amLocal | amDateFormat: 'dddd DD MMMM'}}</span>
        </div>

        <div class="flex flex-col detail" *ngIf="appointmentService.selectedTime">
            <span class="detail__title">Tijd</span>
            <span>{{appointmentService.selectedTime}}</span>
        </div>

        <ng-container *ngIf="appointmentService.clientInformation">
            <mat-divider *ngIf="true" class="my-4"></mat-divider>

            <span class="card__title">Jouw gegevens</span>
            <div class="flex flex-col detail" *ngIf="appointmentService.clientInformation.initials">
                <span class="detail__title">Initialen</span>
                <span>{{ appointmentService.clientInformation.initials }}</span>
            </div>

            <div class="flex flex-col detail" *ngIf="appointmentService.clientInformation.firstName">
                <span class="detail__title">Voornaam</span>
                <span>{{ appointmentService.clientInformation.firstName }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">Achternaam</span>
                <span>{{ appointmentService.clientInformation.lastName }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">E-mail</span>
                <span>{{ appointmentService.clientInformation.email }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">Telefoonnummer</span>
                <span>{{ appointmentService.clientInformation.phoneNumber }}</span>
            </div>

            <div class="flex flex-col detail">
                <span class="detail__title">Opmerkingen</span>
                <span>{{ appointmentService.clientInformation.remarks }}</span>
            </div>
        </ng-container>
    </ng-container>
</app-step-card>
