import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTextareaInputComponent } from './form-textarea-input.component';
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";

@NgModule({
    declarations: [
        FormTextareaInputComponent
    ],
    exports: [
        FormTextareaInputComponent
    ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormErrorModule,
    MatInputModule
  ]
})
export class FormTextareaInputModule { }
