import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {CreateAppointmentComponent} from "./pages/create-appointment/create-appointment.component";
import {ConfirmationComponent} from "./pages/create-appointment/steps/confirmation/confirmation.component";
import {QuestionnairesComponent} from "./pages/questionnaires/questionnaires.component";
import {LoginComponent} from "./pages/login/login.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {AppointmentsComponent} from "./pages/appointments/appointments.component";
import {CanDeactivateGuard} from "@core/guards/can-deactivate.guard";
import {AuthGuard} from "@core/guards/auth.guard";
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'consulten',
    component: AppointmentsComponent,
    canActivate: [AuthGuard]
  },
/*  {
    path: 'form-entries',
    loadChildren: () => import('./pages/form-entries/form-entries.module').then(m => m.FormEntriesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forms',
    loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormsModule),
    canActivate: [AuthGuard]
  },*/
  {
    path: 'afspraak-maken',
    component: CreateAppointmentComponent
  },
  {
    path: 'afspraak-maken/bedankt',
    component: ConfirmationComponent
  },
  {
    path: 'vragenlijsten',
    component: QuestionnairesComponent,
    canActivate: environment.appEnv === 'production' ? [AuthGuard] : [],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'mijn-profiel',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
