import {InjectionToken} from '@angular/core';
import {patternToError} from '@util/validators/validatorHelper';
// import {FormConstants} from '@util/forms/form-helpers';

interface ErrorData {
  text: string;
  onSubmit?: boolean;
  onChange?: boolean;
  showPopover?: boolean;
}

const createError = (errorData: ErrorData): ErrorData => errorData;
export const defaultErrors = {
  required: () => createError({ text: 'Dit veld is verplicht', onSubmit: true, onChange: true }),
  minlength: (error: any) => createError({ text: `Dit veld moet minimaal ${error.minlength.requiredLength} tekens bevatten`, onChange: true, onSubmit: true }),
  maxlength: (error: any) => createError({ text: `Dit veld mag maximaal ${error.maxlength.requiredLength} tekens bevatten`, onChange: true, onSubmit: true }),
  noMinOneSelected: () => createError({ text: 'Maak een selectie', onSubmit: true, onChange: true, showPopover: true }),
  pattern: (error: any) => createError({text: patternToError(error.pattern), onChange: true, onSubmit: true}),
  iban: () => 'Incorrecte IBAN. Voorbeeld: NL05 RABO 1234 1234 00',
  cocNumberInvalid: () => 'Dit KVK nummer is ongeldig. Het moet 8 cijfers bevatten',
  vatNumberInvalid: () => 'Dit NTW nummer is ongeldig. Voorbeeld: NL123456789B01',
  zdNumberIncomplete: () => 'Dit ZD nummer is onvolledig',
  emailNotTheSame: () => createError({ text: 'De e-mailadressen komen niet overeen', onSubmit: true, onChange: true }),
  mobileInvalid: () => createError({ text: 'Dit is geen valide mobiel 06-nummer. Voorbeeld: +31612345678 | 0031612345678 | 0612345678 (geen spaties of streepjes)', onSubmit: true, onChange: true }),
  bsn11: () => 'Dit BSN-nummer is niet geldig',
  requireCheckboxesToBeChecked: () => createError({ text: 'Er moet minimaal één optie aangevinkt zijn', onSubmit: true, onChange: true }),
  hasToBeTrue: () => createError({ text: 'Dit veld is verplicht', onSubmit: true, onChange: true }),
  requiredPopover: () => createError({ text: 'Dit veld is verplicht', showPopover: true }),
  valuesAreFalsy: () => ({
    text: `Niet alle waarden zijn (correct) ingevuld`,
    showPopover: true,
    onSubmit: true,
    onChange: true
  }),
  valuesMissing: () => ({
    text: `Niet alle waarden zijn (correct) ingevuld`,
    showPopover: true,
    onSubmit: true
  }),
  minSelected: () => ({ // FIXME: can we dynamically get what "min" value was passed?
    text: `U dient minimaal één optie te selecteren`,
    showPopover: true,
    onSubmit: true,
    onChange: true
  }),
  minArrayLength: (error: any) => {
    const requiredLength = error.minArrayLength.requiredLength;
    let text = `Er moet minimaal ${requiredLength} veld zijn`;
    if (requiredLength > 1) {
      text = `Er moeten minimaal ${requiredLength} velden zijn`;
    }

    return createError({ text, onChange: true, onSubmit: true });
  },
};
export const patternErrors = {
  // pattern(['"^[a-z0-9_-]{8,15}$"; ']): (error) => 'Veld mag alleen letters bevatten',
  // pattern: ({lettersOnlyPattern}) => 'Veld mag alleen letters bevatten',
};

export const serverErrors = {

};

export const PATTERN_ERRORS = new InjectionToken('PATTERN_ERRORS', {
  providedIn: 'root',
  factory: () => patternErrors
});

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
