import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicalInformationComponent } from './medical-information.component';
import {BasePageModule} from "../base-page/base-page.module";
import {RouterLink} from "@angular/router";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    MedicalInformationComponent
  ],
    imports: [
        CommonModule,
        BasePageModule,
        RouterLink,
        FormInputsModule,
        ReactiveFormsModule
    ]
})
export class MedicalInformationModule { }
