import {Component, EventEmitter, Input, Output} from '@angular/core';
import {priceNotation} from "@util/helpers/money-helper";

@Component({
  selector: 'app-treatment-item',
  templateUrl: './treatment-item.component.html',
  styleUrls: ['./treatment-item.component.scss']
})
export class TreatmentItemComponent {
  @Input({required: true}) treatment: any;
  @Input({required: true}) selectedTreatment: any;
  @Output() selectTreatment: EventEmitter<any> = new EventEmitter<any>();
    protected readonly priceNotation = priceNotation;
}
