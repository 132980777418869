import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";

@Component({
  selector: 'app-form-dropdown-input',
  templateUrl: './form-dropdown-input.component.html',
  styleUrls: ['./form-dropdown-input.component.scss']
})
export class FormDropdownInputComponent extends BaseFormInputComponent implements OnInit {
  public searchable = true;
  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    // Select the only option if there is only one option
    if (this.input.selectOptions.length === 1) {
      const control = this.input.formGroupName ? this.formGroup.get(this.input.formGroupName).get(this.input.key) : this.formGroup.get(this.input.key);
      if (this.input.multi) {
        control.setValue([this.input.selectOptions[0].value]);
      } else {
        control.setValue(this.input.selectOptions[0].value);
      }
    }
  }

  setScroll() {
    if (this.input.key === 'eyeCorrectionStrength') {
      setTimeout(() => {
        const scrollHost = document.querySelector('.scroll-host');
        if (scrollHost) {
          scrollHost.scrollTop = (scrollHost.scrollHeight / 2) - 125;
        }
      });
    }
  }

  public getArray(key): any {
    const group = this.input.formGroupName ? this.formGroup.get(this.input.formGroupName) : this.formGroup;
    return group.get(key);
  }

  onTypeRemoved(type, key) {
    const types = this.getArray(key).value as string[];
    this.removeFirst(types, type);
    this.getArray(key).patchValue(types);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}
