import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseButtonComponent } from './base-button.component';
import {MatButtonModule} from "@angular/material/button";



@NgModule({
    declarations: [
        BaseButtonComponent
    ],
    exports: [
        BaseButtonComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule
    ]
})
export class BaseButtonModule { }
