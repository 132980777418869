import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatListModule} from "@angular/material/list";



@NgModule({
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent
  ],
    imports: [
        CommonModule,
        BaseButtonModule,
        FontAwesomeModule,
        MatListModule
    ]
})
export class FooterModule { }
