import {DropdownData, FormInputType} from "@util/types/interfaces";
import {ExamType} from "@util/types/models";

export const FormInputTypeDropdown: DropdownData[] = [
  {value: FormInputType.Text, name: 'Tekst'},
  {value: FormInputType.Select, name: 'Selecteer'},
  {value: FormInputType.Textarea, name: 'Tekstveld'},
  {value: FormInputType.SingleCheckbox, name: 'Selectievakje'},
  {value: FormInputType.SingleRadio, name: 'Keuzerondje'},
  {value: FormInputType.DatePicker, name: 'Datum'},
];

export const ExamTypeDropdown: DropdownData[] = [
  {name: 'Fundusscreening', value: ExamType.FundusScreening},
  {name: 'Rijbewijskeuring 107', value: ExamType.Rijges107},
  {name: 'Rijbewijskeuring 095', value: ExamType.Rijges095},
  {name: 'Rijbewijskeuring 104', value: ExamType.Rijges104},
  {name: 'Optometrisch onderzoek', value: ExamType.OptometricExamination},
  {name: 'Glaucoomscreening', value: ExamType.GlaucomaScreening},
  {name: 'Brilmeting', value: ExamType.GlassesMeasurement},
  {name: 'Orthoptisch onderzoek', value: ExamType.OrthopticExamination},
  {name: 'Droge ogen', value: ExamType.DryEyes},
  {name: 'Visusdaling', value: ExamType.VisualImpairment},
  {name: 'Vlekken en flitsen', value: ExamType.SpotsAndFlashes},
]

export const YesNoDropdown: DropdownData[] = [
  {name: 'Nee', value: 'no'},
    {name: 'Ja', value: 'yes'},
];

export const eyeCorrectionDropdown: DropdownData[] = Array.from({length: 81}, (_, i) => i - 40).map(i => ({name: `${(i / 4) > 0 ? '+' : ''}${(i / 4).toString()}`, value: (i / 4).toString()}));

// TODO: move this somewhere else.
const healthcareTypes = [
  {
    "Code": "01",
    "Omschrijving": "Huisartsen"
  },
  {
    "Code": "02",
    "Omschrijving": "Apothekers"
  },
  {
    "Code": "03",
    "Omschrijving": "Medisch Specialisten"
  },
  {
    "Code": "04",
    "Omschrijving": "Fysiotherapeuten"
  },
  {
    "Code": "05",
    "Omschrijving": "Logopedisten"
  },
  {
    "Code": "07",
    "Omschrijving": "Oefentherapeuten"
  },
  {
    "Code": "08",
    "Omschrijving": "Verloskundigen"
  },
  {
    "Code": "11",
    "Omschrijving": "Tandarts - Specialisten (Mondziekten en Kaakchirurgie)"
  },
  {
    "Code": "12",
    "Omschrijving": "Tandartsen"
  },
  {
    "Code": "13",
    "Omschrijving": "Tandarts - Specialisten (Dento - Maxillaire Orthopedie)"
  },
  {
    "Code": "14",
    "Omschrijving": "Bedrijfsartsen"
  },
  {
    "Code": "24",
    "Omschrijving": "Dietisten"
  },
  {
    "Code": "26",
    "Omschrijving": "Podotherapeuten"
  },
  {
    "Code": "33",
    "Omschrijving": "Kraamzorg"
  },
  {
    "Code": "44",
    "Omschrijving": "Optometristen"
  },
  {
    "Code": "57",
    "Omschrijving": "Physician Assistant"
  },
  {
    "Code": "84",
    "Omschrijving": "Overige Artsen"
  },
  {
    "Code": "87",
    "Omschrijving": "Mondhygienisten"
  },
  {
    "Code": "88",
    "Omschrijving": "Ergotherapeuten"
  },
  {
    "Code": "89",
    "Omschrijving": "Schoonheidsspecialisten"
  },
  {
    "Code": "90",
    "Omschrijving": "Overige therapeuten en Complementair en Aanvullende zorg"
  },
  {
    "Code": "91",
    "Omschrijving": "Verpleegkundigen"
  },
  {
    "Code": "93",
    "Omschrijving": "Tandtechnici / Tandprothetici"
  },
  {
    "Code": "94",
    "Omschrijving": "Psychologische Zorgverleners"
  },
  {
    "Code": "96",
    "Omschrijving": "Pedicuren"
  }
];

export const HealthcareTypeDropdown: DropdownData[] = healthcareTypes.map(type => ({
  value: type.Code,
  name: type.Omschrijving
}));

export const HealthcareRegionDropdown: DropdownData[] = [
  {value: 'H', name: 'Zuid-Holland'},
  {value: 'L', name: 'Noord-Holland'},
  {value: 'M', name: 'Utrecht'},
  {value: 'S', name: 'Zeeland'},
  {value: 'K', name: 'Limburg'},
  {value: 'E', name: 'Overijssel'},
  {value: 'A', name: 'Groningen'},
  {value: 'B', name: 'Friesland'},
  {value: 'D', name: 'Drenthe'},
  {value: 'G', name: 'Gelderland'},
  {value: 'P', name: 'Noord-Brabant'},
];
