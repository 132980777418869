import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-base-form-input',
  templateUrl: './base-form-input.component.html',
  styleUrls: ['./base-form-input.component.scss']
})
export class BaseFormInputComponent implements AfterViewInit, AfterViewChecked {
  public hasError = false;
  @ViewChild('errorElement') errorElement: ElementRef;

  get formGroupName(): string {
    return this._formGroupName;
  }

  @Input() set formGroupName(value: string) {
    if(!value) return;
    this._formGroupName = value;
  }
  get formKey(): string {
    return this._formKey;
  }

  @Input() set formKey(value: string) {
    if(!value) return;
    this._formKey = value;
  }

  private _formKey: string;
  @Input() formGroup: FormGroup;
  @Input() input;
  private _formGroupName: string = null;

  // @Output()
  // public events: EventEmitter<FormEvent> = new EventEmitter<FormEvent>(); // TODO: add typings for events.

  constructor(
    protected cdr: ChangeDetectorRef
  ) { }


  safeAccessPath = (obj, path) => {
    return path.split('.').reduce((o, p) => o && o[p], obj);
  }

  ngAfterViewInit(): void {
    // If there is an initial value, set it
    if (this.input.initialValue && !this.formGroup.get(this.input.key).value) {
      this.formGroup.get(this.input.key).setValue(this.input.initialValue);
    }
  }

  ngAfterViewChecked() {
    if (this.errorElement) {
      this.findFormError(this.errorElement.nativeElement);
      this.cdr.detectChanges();
    }
  }

  public getFormGroup(key): FormGroup {
    return this.formGroup.get(key) as FormGroup;
  }

  public getKeys(group: any): string[] {
    return Object.keys(group.controls).map((c, i) => c);
  }

  public getValueName(value): any {
    return this.input.selectOptions.find(o => o.value === value).name;
  }

  log(value): void {
    console.log(value);
  }

  getControl(): AbstractControl {
    return this.input.formGroupName ? this.formGroup.get(this.input.formGroupName).get(this.input.key) : this.formGroup.get(this.input.key);
  }

  findFormError(element): void {
    const control = this.getControl();
    if (!control) return;

    this.hasError = !!element.querySelector('form-error') && control?.invalid;
  }

  isDisabled(): boolean {
    const control = this.getControl();
    if (!control) return false;

    return control.disabled;
  }
}
