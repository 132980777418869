import {CreateHotToastRef, HotToastService, Toast, ToastOptions} from "@ngneat/hot-toast";

export enum ToastType {
  Success = 'success',
  Error = 'error',
  Loading = 'loading',
  Warning = 'warning',
  Regular = 'regular',
  Show = 'show',
  Info = 'info',
  Observe = 'observe',
}

export function openToast(toast: HotToastService, message: string, type = ToastType.Show, customSettings?: ToastOptions<any>): CreateHotToastRef<any> {
  const settings = customSettings ? customSettings : DefaultToastSettings;
  if (type !== ToastType.Loading) {
    settings.style = getStyles(type);
  }

  return toast[type](message, settings);
}

const DefaultToastStyling = {
  border: '1px solid #B8BABF',
  borderRadius: '4rem',
  padding: '.5rem .75rem',
  color: '#416EFF',
  maxWidth: '100%',
};

function getStyles(type: ToastType) {
  switch (type) {
    case ToastType.Success:
      return {
        ...DefaultToastStyling,
        backgroundColor: '#E7F7E7',
        color: '#1F7A00',
        borderColor: '#1F7A00',
      }
    case ToastType.Error:
      return {
        ...DefaultToastStyling,
        backgroundColor: '#FAE7E7',
        color: '#FF001F',
        borderColor: '#FF001F',
      }
    case ToastType.Warning:
      return {
        ...DefaultToastStyling,
        backgroundColor: '#FDF7D8',
        color: '#7A6A15',
        borderColor: '#7A6A15',
      }
    default:
      return DefaultToastStyling;
  }
}

export const DefaultToastSettings: ToastOptions<unknown> = {
  duration: 5000,
  position: 'bottom-center',
  dismissible: true,
  style: DefaultToastStyling,
  iconTheme: {
    primary: '#713200',
    secondary: '#FFFAEE',
  },
}

// Custom message are sent from backend, e.a. "Verification code is incorrect!".
export function getErrorMessage(environment: string, errorCode: number, customMessage?: string): {message: string, type: ToastType, showInProduction?: boolean} {
  const error = ErrorMessageMap[errorCode];
  if (!error) {
    return {
      message: `Onbekende error ${errorCode}, zie log.`,
      type: ToastType.Warning,
      showInProduction: true
    };
  }

  if (environment === 'local' || environment === 'staging') {
    return {
      message: customMessage ? customMessage : error.message['development'],
      type: error.type,
      showInProduction: error.showInProduction
    };
  }

  return {
    message: customMessage ? customMessage : error.message[environment],
    type: error.type,
    showInProduction: error.showInProduction
  };
}

// TODO: Move?
// TODO: Set more errors codes, update texts.
export const ErrorMessageMap : {
  [key: string]: {
    message: {
      development: string,
      production: string,
    },
    showInProduction: boolean,
    type?: ToastType
  }
} = {
  400: {
    message: {
      development: "Bad request. Please check your request.",
      production: "Invoer is incorrect. Probeer het nog een keer."
    },
    showInProduction: false,
    type: ToastType.Warning
  },
  401: {
    message: {
      development: "Unauthorized access. Please check your credentials.",
      production: "Geen toegang. (401)"
    },
    showInProduction: false,
    type: ToastType.Warning
  },
  403: {
    message: {
      development: "Forbidden access. You do not have the right permissions. (403)",
      production: "Deze actie is verboden. (403)"
    },
    showInProduction: true,
    type: ToastType.Warning
  },
  404: {
    message: {
      development: "Not found. Please check your request.",
      production: "Niet gevonden. (404)"
    },
    showInProduction: true,
    type: ToastType.Warning
  },
  500: {
    message: {
      development: "Internal Server Error. Check server logs for more details.",
      production: "Server error. (5xx)"
    },
    showInProduction: true,
    type: ToastType.Error
  }
}
