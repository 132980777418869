// Credits: https://stackoverflow.com/questions/45019317/matdatepicker-start-week-on-monday/45020144
import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from '@angular/core';
import * as moment from "moment";
moment.locale('nl');

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // parse the date from input component as it only expect dates in
  // mm-dd-yyyy format
  override parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }

  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];
  }

  // // fixes annoying tz issue when parsing 'YYYY-MM-DD' dates through JS Date constructor.
  // deserialize(value): Date | null {
  //   return value ? moment(value).toDate() : null;
  // }
}
