import {Component, OnInit} from '@angular/core';
import {BaseLoginStepComponent} from "../base-login-step/base-login-step.component";
import {FormInput, FormInputType} from "@util/types/models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "@core/services/login.service";
import {firstValueFrom} from "rxjs";
import {SecurityService} from "@core/services/security.service";
import {Router} from "@angular/router";
import {HotToastService} from "@ngneat/hot-toast";
import {openToast, ToastType} from "@util/helpers/toastHelper";

@Component({
  selector: 'app-validate-code-step',
  templateUrl: './validate-code-step.component.html',
  styleUrls: ['./validate-code-step.component.scss']
})
export class ValidateCodeStepComponent extends BaseLoginStepComponent implements OnInit {
  protected readonly FormInputType = FormInputType;
  public phoneInput: FormInput = {
    label: 'Vul hier de code in',
    type: FormInputType.Text,
    key: 'code',
  };
  public loginForm: FormGroup;

  constructor(
      private formBuilder: FormBuilder,
      override loginService: LoginService,
      private securityService: SecurityService,
      private router: Router,
      private toastService: HotToastService
  ) {
    super(loginService);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  async validate(): Promise<void> {
    if (this.loginForm.valid) {
      try {
        const verifyPhoneNr = await firstValueFrom(this.loginService.verifySmsToken(this.loginForm.value.code, this.loginService.userId));
        if(!verifyPhoneNr) {
          throw new Error('Token validation failed!');
        }

        this.securityService.accessToken = verifyPhoneNr.accessToken;

        this.router.navigate(['/dashboard']);
      } catch(e) {
        openToast(this.toastService, 'Incorrecte code.', ToastType.Error);
        console.error(e);
      }
    }
  }
}
