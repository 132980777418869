import {Component, Input} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent {
  @Input() id: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() action: { label: string, link: string, icon: IconProp };
}
