<div class="flex flex-col lg:flex-row gap-4 lg:gap-10">
    <div class="flex-1">
        <h2 class="mb-4">{{loginService.currentStep.inputs.title}}</h2>
        <div class="text-xl">{{loginService.currentStep.inputs.subtitle}}</div>
    </div>

    <div class="flex flex-col flex-1">
        <form class="" (ngSubmit)="validate()">
            <app-form-inputs
                    [input]="phoneInput"
                    [formGroup]="loginForm"
            ></app-form-inputs>

            <button #hiddenButton hidden></button>
        </form>
        <app-button (onClick)="hiddenButton.click()" class="mt-6">
            Volgende
            <fa-icon class="ms-2" [icon]="['far', 'arrow-right-long']"></fa-icon>
        </app-button>
    </div>
</div>
