import {Component, Input} from '@angular/core';
import {LoginService} from "@core/services/login.service";

@Component({
  selector: 'app-base-login-step',
  templateUrl: './base-login-step.component.html',
  styleUrls: ['./base-login-step.component.scss']
})
export class BaseLoginStepComponent {
  @Input({required: true}) public title: string;
  @Input({required: true}) public subtitle: string;

  constructor(protected loginService: LoginService) {
  }

  displayNextStep() {
    const nextStepFunction = this.loginService.currentStep.inputs.nextStepFunction;
    if (nextStepFunction) {
      nextStepFunction();
    }

    // this.nextStep.emit();
    //
    // if(this.canProceed) {
    //   // Reset the current ad index back to `0` when we reach the end of an array.
    //   if (this.stepService.currentStepIndex === this.stepList.length) {
    //     return;
    //   }

      this.loginService.currentStepIndex++;
      window.scrollTo(0, 0);
  }

  displayPreviousStep() {
    if (this.loginService.currentStepIndex === 0) {
      // return to location overview on website
      return;
    }

    // Reset the current ad index back to the last index when we reach the beginning of an array.
    if (this.loginService.currentStepIndex === -1) {
      return;
    }

    this.loginService.currentStepIndex--;
  }
}
