import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    public apiUrl = environment.apiUrl + '/public/mollie-payment';
    constructor(public http: HttpClient) { }

    getAll(): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/public/mollie-payment');
    }

    createPayment(data: {issuerId?: string, method: string}, appointment: any): Observable<any> {
        const startDate = moment((appointment.calendarEntry.start as any)).format('DD-MM-YYYY HH:MM')
        console.log(appointment)
        console.log(appointment.examinationProtocol)
        const paymentBody = {
            amount: {
                value: appointment.examinationProtocol.price.toString() + ".00", // FIXME: add .00 in backend
                currency: 'EUR'
            },
            description: appointment.examinationType.prettyName + ` - ${appointment.patient.firstName} - ` + startDate,
            redirectUrl: `${environment.appUrl}/afspraak-maken/bedankt`,
            webhookUrl: `${environment.devWebhookUrl}`,
            method: data.method,
            issuer: data.issuerId
        }

        return this.http.post<any>(`${environment.apiUrl}/public/appointment/${appointment.id}/mollie-payment`, paymentBody);
    }

    getMethods(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/public/mollie-payment/methods`);
    }

    checkPayment(appointmentId: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/public/appointment/${appointmentId}/mollie-payment`);
    }
}
