import {Component} from "@angular/core";
import {BaseQuestionnaireTypeComponent, Section} from "../base-questionnaire-type/base-questionnaire-type.component";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {FormBuilder} from "@angular/forms";
import {
    additionalInformation, dryEyesCorrectionQuestions, dryEyesEyeHealthQuestions, dryEyesGeneralHealthQuestions,
    explanation,
    SectionTitles, spotsAndFlashesCorrectionQuestions, spotsAndFlashesEyeHealthQuestions
} from "../sharedQuestions";
import {SingleRadioInput} from "@util/types/interfaces";
import {PatientService} from "@core/services/patient.service";
import {HotToastService} from "@ngneat/hot-toast";
import {Router} from "@angular/router";

@Component({
    selector: 'app-dry-eyes-questionnaire',
    templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
})
export class SpotsAndFlashesQuestionnaireComponent extends BaseQuestionnaireTypeComponent {
    constructor(
        override questionnairesService: QuestionnaireService,
        override formBuilder: FormBuilder,
        override patientService: PatientService,
        override toastService: HotToastService,
        override router: Router,
    ) {
        const sections: Section[] = [
            {
                title: SectionTitles.Complaint,
                inputs: [
                    explanation
                ],
            },
            {
                title: SectionTitles.EyeCorrection,
                inputs: spotsAndFlashesCorrectionQuestions
            },
            {
                title: SectionTitles.EyeHealth,
                inputs: spotsAndFlashesEyeHealthQuestions
            },
            {
                title: SectionTitles.Other,
                inputs: [additionalInformation]
            }
        ];

        super(questionnairesService, formBuilder, patientService, sections, toastService, router);
    }
}
