import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimePickerComponent } from './date-time-picker.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MomentModule} from "ngx-moment";
import {MatDividerModule} from "@angular/material/divider";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
    declarations: [
        DateTimePickerComponent
    ],
    exports: [
        DateTimePickerComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MomentModule,
        MatDividerModule,
        BaseButtonModule,
        MatTooltipModule
    ]
})
export class DateTimePickerModule { }
