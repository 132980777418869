<div #errorElement>
  <div
    class="d-flex errorWrapper"
    [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
  >
  <mat-form-field class="formField errorWrapper w-full" formErrorContainer>
      <input
        matInput
        placeholder="DD - MM - YYYY"
        [formControlName]="input.key"
        [inputMask]="dateInputMask"
      />

      <fa-icon
        *ngIf="isDisabled()"
        matSuffix
        [icon]="['far', 'lock-keyhole']"
        class="disabledIcon"
      ></fa-icon>
    </mat-form-field>
  </div>
</div>
