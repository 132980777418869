import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLoginStepComponent } from './base-login-step.component';
import {EmailLoginStepModule} from "../email-login-step/email-login-step.module";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {ValidateCodeStepComponent} from "../validate-code-step/validate-code-step.component";
import {ConfirmPhoneStepComponent} from "../confirm-phone-step/confirm-phone-step.component";
import {FormsModule} from "@angular/forms";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";



@NgModule({
  declarations: [
    BaseLoginStepComponent,
      ValidateCodeStepComponent,
      ConfirmPhoneStepComponent
  ],
  imports: [
    CommonModule,
    EmailLoginStepModule,
    FormErrorModule,
    FormsModule,
    BaseButtonModule,
    FontAwesomeModule,
    FormInputsModule
  ]
})
export class BaseLoginStepModule { }
