import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormEntryDialogComponent } from './form-entry-dialog.component';
import {BaseDialogModule} from "@components/organisms/dialogs/base-dialog/base-dialog.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {CdkDropList, DragDropModule} from "@angular/cdk/drag-drop";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {IconButtonModule} from "@components/atoms/buttons/icon-button/icon-button.module";
import {FormDevToolsModule} from "@components/molecules/forms/form-dev-tools/form-dev-tools.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";



@NgModule({
  declarations: [
    FormEntryDialogComponent
  ],
  imports: [
    CommonModule,
    BaseDialogModule,
    BaseButtonModule,
    CdkDropList,
    DragDropModule,
    FontAwesomeModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    IconButtonModule,
    FormDevToolsModule,
    FormsModule,
    FormErrorModule,
    ReactiveFormsModule
  ]
})
export class FormEntryDialogModule { }
