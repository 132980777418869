import {Component, OnInit} from '@angular/core';
import {CompanyLocationService} from "@core/services/company-location.service";
import {parseAddress} from "@util/helpers/name-helper";
import {checkIfImageExists} from "@util/helpers/checkIfImageExists";

@Component({
  selector: 'app-selected-location-card-header',
  templateUrl: './selected-location-card-header.component.html',
  styleUrls: ['./selected-location-card-header.component.scss']
})
export class SelectedLocationCardHeaderComponent implements OnInit {
    public locationLogo: string = '';
    constructor(
        public locationService: CompanyLocationService
    ) {}

    protected readonly parseAddress = parseAddress;

    async ngOnInit() {
        // FIXME: show location logo.
        // this.locationLogo = await checkIfImageExists(this.locationService.activeLocation.name);
    }
}
