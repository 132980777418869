<div
  formErrorContainer
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup">
    <mat-form-field class="formField errorWrapper w-full" formErrorContainer>
    <input
      matInput
      [matDatepicker]="picker"
      (click)="picker.open()"
      [formControlName]="input.key"
      readonly
      autocomplete="off">
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  </mat-form-field>
</div>
