import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSingleCheckboxInputComponent } from './form-single-checkbox-input.component';
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [
        FormSingleCheckboxInputComponent
    ],
    exports: [
        FormSingleCheckboxInputComponent
    ],
    imports: [
        CommonModule,
        FormErrorModule,
        MatCheckboxModule,
        ReactiveFormsModule
    ]
})
export class FormSingleCheckboxInputModule { }
