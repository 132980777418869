import {Component, Input} from '@angular/core';
import {StepService} from "@core/services/step.service";
import {CompanyLocationService} from "@core/services/company-location.service";
import {ScheduleService} from "@core/services/schedule.service";
import * as moment from 'moment';
import {AppointmentService} from "@core/services/appointment.service";
import {BaseStepComponent} from "../base-step/base-step.component";
@Component({
  selector: 'app-select-date-time',
  templateUrl: './select-date-time.component.html',
  styleUrls: ['./select-date-time.component.scss']
})
export class SelectDateTimeComponent extends BaseStepComponent {

  constructor(
      public locationService: CompanyLocationService,
      public appointmentService: AppointmentService,
      override stepService: StepService,
    ) {
      super(stepService);
  }
}
