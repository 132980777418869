import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import {ReactiveFormsModule} from "@angular/forms";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FormDevToolsModule} from "@components/molecules/forms/form-dev-tools/form-dev-tools.module";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {FormEntryDialogModule} from "@components/organisms/dialogs/form-entry-dialog/form-entry-dialog.module";



@NgModule({
  declarations: [
    DynamicFormComponent
  ],
  exports: [
    DynamicFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormInputsModule,
    BaseButtonModule,
    FormDevToolsModule,
    FormErrorModule,
    FormEntryDialogModule
  ]
})
export class DynamicFormModule { }
