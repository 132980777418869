<app-base-page>
    <mat-spinner *ngIf="!patient" class="mx-auto my-32" diameter="30"></mat-spinner>
    <ng-container *ngIf="patient">
        <h4>Welkom {{ parseName(patient) }}</h4>
        <span>Het is vandaag {{ today | amLocal | amDateFormat: 'DD MMMM yyy'}}</span>

        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
            <app-section
                    [title]="'Eerstvolgende afspraak'"
                    class="col-start-1 col-end-4 lg:col-start-auto lg:col-end-auto"
                    *ngIf="appointments?.length > 0"
            >
                <div class="flex flex-col gap-3 flex-1">
                    <div class="flex flex-col font-bold">
                        <div>{{appointments[0].examinationProtocol.examinationType.name}}</div>
                        <div class="text-deepsea-1">{{ appointments[0].calendarEntry.start | amLocal | amDateFormat: 'DD MMMM yyy' }} <span class="text-body font-normal">om</span> {{appointments[0].calendarEntry.start | amLocal | amDateFormat: 'HH:mm'}}</div>
                    </div>

                    <div class="flex flex-col">
                        <div class="font-bold">{{appointments[0].companyLocation.name}}</div>
                        <!--                    FIXME: SUFIX!-->
                        <div>{{appointments[0].companyLocation.address.streetName}} {{appointments[0].companyLocation.address.houseNr}}</div>
                        <div>{{appointments[0].companyLocation.address.postalCode}} {{appointments[0].companyLocation.address.city}}</div>
                    </div>

                    <div class="rounded-xl h-52 mt-auto"
                         leaflet
                         [leafletOptions]="options"
                    ></div>
                </div>
            </app-section>

            <app-section
                    [title]="'Mijn taken'"
                    class="col-start-1 col-end-4 lg:col-span-2"
                    *ngIf="tasks?.length > 0"
            >
                <div class="flex flex-col gap-3">
                    <div *ngFor="let task of tasks" class="cursor-pointer hover:shadow-lg p-6 border rounded-xl border-grey-2 flex"  (click)="task.callback()">
                        <div class="flex flex-col">
                            <h5 class="mb-3">{{task.title}}</h5>
                            <app-chip class="self-start" [color]="'red'">
                                <fa-icon class="me-2" [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                Vul gegevens aan
                            </app-chip>
                        </div>

                        <app-icon-button class="ml-auto self-center" color="secondary">
                            <fa-icon [icon]="['far', 'arrow-right-long']"></fa-icon>
                        </app-icon-button>
                    </div>
                </div>
            </app-section>

            <app-section
                [title]="'Mijn berichten (' + messages.length + ')'"
                [action]="{label: 'Alle berichten', link: '/berichten', icon: ['far', 'arrow-right-long']}"
                class="col-start-1 col-end-4"
                *ngIf="messages.length > 0"
            >
                <div class="flex flex-col gap-3">
                    <div *ngFor="let message of messages" class="cursor-pointer hover:shadow-lg p-6 border rounded-xl border-grey-2 flex">
                        <div class="rounded-full bg-deepsea-1 w-3 h-3 mr-4 mt-2"></div>
                        <div class="flex flex-col">
                            <h5 class="mb-1">{{message}}</h5>
                            <div class="text-grey-3 text-sm">dd-mm-jj</div>
                        </div>

                        <app-icon-button class="ml-auto self-center" color="secondary">
                            <fa-icon [icon]="['far', 'arrow-right-long']"></fa-icon>
                        </app-icon-button>
                    </div>
                </div>
            </app-section>
        </div>
    </ng-container>
</app-base-page>
