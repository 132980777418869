
// TODO: refactor the fullname/informal working. Also use type ducking?
import {User} from "@util/types/models";
import {Gender} from "@util/types/enums";

export function parseName(entity: any, fullName = false, informal = false) {
  if(!entity) {
    return 'Geen naam gevonden.'
  }

  if(!entity.lastName) {
    return entity.firstName;
  }

  if(!entity.initials) {
    informal = true;
  }

  if(informal) {
    return `${entity.firstName} ${entity.middleName ? entity.middleName + ' ' : ''}${entity.lastName}`
  }

  if(fullName) {
    return `${getSalutation(entity)} ${parseInitials(entity.initials)} ${entity.firstName ? `(${entity.firstName}) ` : ''}${entity.middleName ? entity.middleName + ' ' : ''}${entity.lastName}`.trim();
  }

  return `${parseInitials(entity.initials)} ${entity.middleName ? entity.middleName + ' ' : ''}${entity.lastName}`;
}


// removes all special characters in the given value
// loop through the value, add a dot at the end of each letter
export function parseInitials(value): string {
  let parsed = '';

  value = value.replace(/[^a-zA-Z ]/g, "");
  for (let i in value) {
    parsed += value[i].toUpperCase() + '.';
  }

  return parsed;
}

export function getSalutation(entity: any): string {
  if(entity.gender === Gender.Male) {
    return 'Dhr.'
  } else if(entity.gender === Gender.Female) {
    return 'Mevr.'
  } else {
    return '';
  }
}

export function getIconInitials(name: string): string {
  // Take first letter of first and last word
  const words = name.split(' ');
  return words[0][0] + words[words.length - 1][0];
}

export function parseAddress(address: any): string {
  if(!address) {
    return 'Geen adres gevonden.'
  }

  return `${address.streetName} ${address.houseNr}${address.houseNrSuffix ? address.houseNrSuffix : ''}, ${address.city}`
}
