import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormInput} from "@util/types/interfaces";
import {FormInputType} from "@util/types/models";

export interface ConfirmDialogData {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  showWarning?: boolean;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public additionalMessageForm: FormGroup;
  public textInput: FormInput = {
    type: FormInputType.Textarea,
    key: 'message',
    label: 'Bericht',
  }
  @ViewChild('hiddenButton') hiddenButton: HTMLButtonElement;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
      confirmButtonText: string,
      cancelButtonText?: string,
      showWarning?: boolean,
      sendAdditionalMessage?: boolean
    }
  ) {}

  ngOnInit(): void {
    this.additionalMessageForm = this.formBuilder.group({
      message: ['', [Validators.required]]
    }, {updateOn: 'blur'});
  }
}
