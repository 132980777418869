import {Directive, ElementRef, OnDestroy} from '@angular/core';
import {debounceTime, fromEvent} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
// import {scrollToError} from "@util/forms/form-helpers";
// import {HotToastService} from "@ngneat/hot-toast";

@Directive({
  selector: 'form'
})
export class FormErrorSubmitDirective {
  submit$;

  constructor(
    private host: ElementRef<HTMLFormElement>,
    // private toast: HotToastService
  ) {
    setTimeout(() => {
      this.submit$ = fromEvent(this.element, 'submit').pipe(shareReplay(1));
      this.submit$.pipe(debounceTime(200)).subscribe(() => {
        // scrollToError(this.element, this.toast);
      });
    }, 10) // FIXME: this shouldn't be needed. Seems to be caused by popovers.
  }

  get element(): HTMLFormElement {
    return this.host.nativeElement;
  }
}
