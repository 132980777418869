// Generate a new guard: ng generate guard can-deactivate

import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
    ConfirmationDialogComponent
} from "@components/organisms/dialogs/confirmation-dialog/confirmation-dialog.component";

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor(private dialog: MatDialog) {}

    canDeactivate(
        component: CanComponentDeactivate
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (component.canDeactivate) {
            return component.canDeactivate();
        }

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: 'Je verlaat de pagina',
                message: 'Weet je zeker dat je de pagina wilt verlaten?',
                showWarning: true
            }
        });
        return dialogRef.afterClosed();
    }
}
