import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormZdInputComponent } from './form-zd-input.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatInputModule} from "@angular/material/input";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";



@NgModule({
  declarations: [
    FormZdInputComponent
  ],
  exports: [
    FormZdInputComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        MatInputModule,
        FormsModule,
        FormErrorModule
    ]
})
export class FormZdInputModule { }
