import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
// import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

// TODO: implement close handler so extending class can handle close logic.
// @UntilDestroy()
@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss']
})
export class BaseDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() noContentPadding = false;
  @Input() hasLeftSide = false;
  @Input() hasCloseAction = false;
  @Input() visibleOverflow = false;
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('scroll', { static: true }) scrollElement: ElementRef;
  @Output() scrollEndReached = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  getColumns(length): string {
    const colLength = 12 / length;
    return `col-md-${colLength}`
  }

  onScroll(event: any): void {
    if (!event.target) return;

    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    if (scrollTop + offsetHeight >= scrollHeight) {
      this.scrollEndReached.emit();
    }
  }
}
