import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './section.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";



@NgModule({
    declarations: [
        SectionComponent
    ],
    exports: [
        SectionComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule
    ]
})
export class SectionModule { }
