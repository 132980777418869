import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentItemComponent } from './payment-item.component';



@NgModule({
    declarations: [
        PaymentItemComponent
    ],
    exports: [
        PaymentItemComponent
    ],
    imports: [
        CommonModule
    ]
})
export class PaymentItemModule { }
