<ng-container [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup">
  <div #errorElement class="relative errorWrapper w-full" *ngIf="!input.isArray" [class.hasError]="hasError">
    <mat-form-field
        class="formField w-full"
      formErrorContainer
      [questionKey]="input.key"
    >
      <textarea
        matInput
        [formControlName]="input.key"
        autocomplete="off"
        [placeholder]="input.placeholder || ''"
        rows="4"
      ></textarea>

<!--      <fa-icon-->
<!--        *ngIf="isDisabled()"-->
<!--        matSuffix-->
<!--        [icon]="['far', 'lock-keyhole']"-->
<!--        class="disabledIcon"-->
<!--      ></fa-icon>-->
    </mat-form-field>
  </div>

  <ng-container [formArrayName]="input.key" *ngIf="input.isArray">
    <ng-container *ngFor="let group of $any(getFormGroup(input.key).controls); index as groupIndex" [formGroupName]="groupIndex">
      <div #errorElement class="d-flex row" [class.hasError]="hasError">
        <div class="position-relative errorWrapper errorWrapper--adjust" *ngFor="let key of getKeys(group); index as keyIndex" [formGroupName]="key">
          <mat-form-field class="formField w-full" formErrorContainer [questionKey]="input.key">
            <textarea
              matInput
              formControlName="value"
              autocomplete="off"
              [placeholder]="input.placeholder ? input.placeholder[keyIndex] : ''"
              rows="4"
            ></textarea>

<!--            <fa-icon-->
<!--              *ngIf="isDisabled()"-->
<!--              matSuffix-->
<!--              [icon]="['far', 'lock-keyhole']"-->
<!--              class="disabledIcon"-->
<!--            ></fa-icon>-->
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
