import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "@components/organisms/dialogs/base-dialog/base-dialog.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DropdownData, FormInput, FormInputType, HealthcarePartyType} from "@util/types/models";
import {HealthcarePartyService} from "@core/services/healthcare-party.service";
import {firstValueFrom} from "rxjs";
import {APIFilterOption} from "@core/services/base-rest-api.service";
import {HealthcareRegionDropdown, HealthcareTypeDropdown, YesNoDropdown} from "@util/types/dropdowns";

@Component({
  selector: 'app-find-healthcare-provider-dialog',
  templateUrl: './find-healthcare-provider-dialog.component.html',
  styleUrls: ['./find-healthcare-provider-dialog.component.scss']
})
export class FindHealthcareProviderDialogComponent extends BaseDialogComponent implements OnInit {
  public form: FormGroup;
  public findYourText;
  public nameInput: FormInput = {
    label: "Naam",
    key: "name",
    type: FormInputType.Text,
    icon: {prop: ['far', 'magnifying-glass'], position: 'suffix'}
  }
  public locationInput: FormInput = {
    label: "Regio",
    key: "region",
    type: FormInputType.Select,
    selectOptions: HealthcareRegionDropdown,
    icon: {prop: ['far', 'magnifying-glass'], position: 'suffix'}
  }
  public selectedHealthcareProvider: any;
  public parties = null;
  public loading = false;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: { type: string },
      private formBuilder: FormBuilder,
      private healthCarePartyService: HealthcarePartyService,
      override dialogRef: MatDialogRef<FindHealthcareProviderDialogComponent>
  ) {
    super(dialogRef);

    if (this.data.type === 'generalPractitioner') {
      this.title = 'Huisarts zoeken';
      this.findYourText = 'Vind je huisarts';
    } else if (this.data.type === 'pharmacy') {
      this.title = 'Apotheek zoeken';
      this.findYourText = 'Vind je apotheek';
    }

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      region: [null, [Validators.required]]
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  async search() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const type = this.data.type === 'generalPractitioner' ? '01' : '02';
    const items = (await firstValueFrom(this.healthCarePartyService.search(
        this.form.value.name,
        [
          new APIFilterOption('type', [type]),
          new APIFilterOption('region', this.form.value.region),
        ]
    )));

    const individuals = items[HealthcarePartyType.Individual] || [];
    this.parties = [
      ...individuals.map(item => {
        return {
          id: item.hpId,
          firstName: (item.hpData?.Voorletters + '. ' + item.hpData?.Achternaam),
          lastName: '',
          letterName: item.relatedHpData ? this.parseRecipientName(item.relatedHpData) : this.parseRecipientName(item.hpData),
          letterAttention: item.relatedHpData ? this.parseRecipientAttention(item.hpData) : '',
          type: (item.hpData.Kwalificaties[0]?.Omschrijving || ''),
          orgName: ((item.relatedHpData.Naam || '')).replace(/,\s*$/, ""),
          title: item.hpData.Kwalificaties[0]?.Omschrijving,
          agbCode: item.hpAgbCode,
          agbPracticeCode: item.relatedHpAgbCode,
          email: item.hpZorgMailAddress || item.relatedHpZorgMailAddress || 'Geen ZorgMail-adres',
          phone: item.relatedHpData.Communicatiegegevens?.find(c => c.Type.Code === '2')?.Gegeven || 'Geel telefoonnummer',
          address: {
            streetName: item.relatedHpData?.Adressen[0]?.Straat,
            houseNr: item.relatedHpData?.Adressen[0]?.Huisnummer,
            postalCode: item.relatedHpData?.Adressen[0]?.Postcode,
            city: item.relatedHpData?.Adressen[0]?.Plaats
          }
        }
      })
    ]

    this.loading = false;
  }

  public parseRecipientName(data): string {
    let name = '';

    if (data.Naam) {
      name += data.Naam;
    } else {
      name += this.parseRecipientAttention(data)
    }

    return name;
  }

  public parseRecipientAttention(data): string {
    let attention = '';

    if (data.Geslacht) {
      const gender = data.Geslacht.Omschrijving === 'Mannelijk' ? 'dhr. ' : 'mevr. ';
      attention += gender;
    }

    if (data.Voorletters) {
      attention += data.Voorletters + '. ';
    }

    if (data.Tussenvoegsel) {
      attention += data.Tussenvoegsel + ' ';
    }

    if (data.Achternaam) {
      attention += data.Achternaam;
    }

    return attention;
  }
}
