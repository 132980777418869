<app-base-dialog
  [title]="data.title"
>
  <div class="d-flex align-items-center">
    <div class="dialog__warning me-4 d-flex align-items-center justify-content-center" *ngIf="data.showWarning">
      <fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon>
    </div>

    <div class="dialog__message">
      {{data.message}}
    </div>
  </div>

  <div class="dialog__buttons flex gap-4" dialog-actions>
    <app-button
      (onClick)="dialogRef.close()"
    >
      {{data.cancelButtonText || 'Annuleren'}}
    </app-button>

    <app-button
      (onClick)="data.sendAdditionalMessage ? hiddenButton.click() : dialogRef.close(true)"
    >
      {{data.confirmButtonText || 'Bevestigen'}}
    </app-button>
  </div>
</app-base-dialog>
