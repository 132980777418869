import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormEntryFieldDialogComponent } from './form-entry-field-dialog.component';
import {BaseDialogModule} from "@components/organisms/dialogs/base-dialog/base-dialog.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {FormDevToolsModule} from "@components/molecules/forms/form-dev-tools/form-dev-tools.module";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [
    FormEntryFieldDialogComponent
  ],
  imports: [
    CommonModule,
    BaseDialogModule,
    BaseButtonModule,
    MatInputModule,
    FormsModule,
    FormInputsModule,
    FormErrorModule,
    ReactiveFormsModule,
    FormDevToolsModule,
    MatChipsModule,
    MatIconModule
  ]
})
export class FormEntryFieldDialogModule { }
