<app-base-page [centered]="true" title="Consulten" class="profile">
    <mat-spinner *ngIf="appointments?.length === 0" class="mx-auto my-32" diameter="30"></mat-spinner>
    <div class="flex flex-col gap-6" *ngIf="appointments?.length > 0">
        <app-section [title]="'Lopende consulten'">
            <div *ngFor="let appointment of appointments; let last = last"
                 class="border rounded-xl border-grey-2 p-6 flex flex-col gap-6"
                 [ngClass]="{'mb-6' : !last}">
                <div class="flex flex-col gap-3 flex-1">
                    <div class="flex flex-col font-bold">
                        <div>{{appointment.examinationProtocol.examinationType.name}}</div>
                        <div class="text-deepsea-1">{{ appointment.calendarEntry.start | amLocal | amDateFormat: 'DD MMMM yyy' }} <span class="text-body font-normal">om</span> {{appointment.calendarEntry.start | amLocal | amDateFormat: 'HH:mm'}}</div>
                    </div>

                    <div class="flex flex-col">
                        <div class="font-bold">{{appointment.companyLocation.name}}</div>
                        <!--                    FIXME: SUFIX!-->
                        <div>{{appointment.companyLocation.address.streetName}} {{appointment.companyLocation.address.houseNr}}</div>
                        <div>{{appointment.companyLocation.address.postalCode}} {{appointment.companyLocation.address.city}}</div>
                    </div>
                </div>
            </div>
        </app-section>

<!--        <app-section [title]="'Oude consulten'">-->
<!--            <div *ngFor="let appointment of appointments" ></div>-->
<!--        </app-section>-->
    </div>
</app-base-page>
