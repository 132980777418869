<section [id]="id ?? title" class="card flex flex-col scroll-m-40 h-full">
    <div class="text-deepsea-3 mb-4 flex">
        <div>
            <h4 class="font-bold">{{title}}</h4>
            <h5 *ngIf="subtitle" class="text-grey-3 font-normal mt-1">{{subtitle}}</h5>
        </div>

        <a class="cursor-pointer hover:underline text-deepsea-1 ml-auto" *ngIf="action">
            {{action.label}}
            <fa-icon class="ml-2" *ngIf="action.icon" [icon]="action.icon"></fa-icon>
        </a>
    </div>

    <ng-content></ng-content>
</section>
