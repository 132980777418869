import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepCardFooterComponent } from './step-card-footer.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";



@NgModule({
    declarations: [
        StepCardFooterComponent
    ],
    exports: [
        StepCardFooterComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        BaseButtonModule
    ]
})
export class StepCardFooterModule { }
