import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {SortDirection} from "@angular/material/sort";
// import {Abilities, Ability, MongoAbility, PureAbility} from "@casl/ability";
import {Optional} from "@angular/core";
import {SecurityService} from "@core/services/security.service";
import {PaginatedResult} from "@util/types/interfaces";

moment.locale('nl');

export class APIFilterOption<T> {
  constructor(public key: (keyof T | string),
              public value: string | number | string[] | number[] | object,
              public exact = false) {}
}

export class APISortOptions<T> {
  //active: (keyof T & string); // TODO: interoperability with mat-angular Sort class would be great, also for type checking.
  active: string;
  direction: SortDirection;
}

export abstract class BaseRestAPIService<ReadDTO extends { id }, CreateDTO, UpdateDTO> {
  protected constructor(
      protected http: HttpClient,
      protected apiUrl: string,
      protected mapper: (input: any) => ReadDTO,
      protected securityService: SecurityService,
      // @Optional() protected ability?: PureAbility
  ) {}

  get(id: string): Observable<ReadDTO> {
    return this.http.get<ReadDTO>(
      `${this.apiUrl}/${id}`, this.securityService.getHttpRequestOptions()
    );
  }

  getAll(pageIndex = 1,
         pageSize = 10,
         search: string = null,
         filter: APIFilterOption<ReadDTO>[] = [],
         sort: APISortOptions<ReadDTO> = {active: 'id', direction: 'asc'}): Observable<PaginatedResult<ReadDTO>> {

    const filterOptions = this.filtersToUrl(filter);

    if (search && search !== '') {
      filterOptions['search'] = search;
    }

    const sortParam = {};
    if (sort) {
      sortParam[sort.active as string] = sort.direction;
    }

    return this.http
      .get<PaginatedResult<ReadDTO>>(
        `${this.apiUrl}?page=${pageIndex}&limit=${pageSize}
            &filter=${JSON.stringify(filterOptions)}&sort=${JSON.stringify(sortParam)}`,
        this.securityService.getHttpRequestOptions()
      )
      .pipe(
        map(response => {
          response.items = response.items.map(plain =>
            this.mapper(plain)
          );
          return response;
        })
      );
  }

  create(dto: CreateDTO): Observable<ReadDTO> {
    return this.http.post<ReadDTO>(
      this.apiUrl,
      dto,
      this.securityService.getHttpRequestOptions()
    );
  }

  update(id: string, dto: UpdateDTO): Observable<ReadDTO>  {
    return this.http.patch<ReadDTO>(
      `${this.apiUrl}/${id}`,
      dto,
      this.securityService.getHttpRequestOptions()
    );
  }

  delete(id: string): Observable<any>  {
    return this.http.delete<ReadDTO>(
      `${this.apiUrl}/${id}`,
      this.securityService.getHttpRequestOptions()
    );
  }

  // TODO: move to util folder?
  // https://stackoverflow.com/a/26909377
  static strToObj(strArr, val, startIdx = 0) {
    let i, obj = {}, x = obj;
    for(i = startIdx; i<strArr.length-1; i++) {
      x = x[strArr[i]] = {};
    }

    x[strArr[i]] = val;
    return obj;
  }

  protected filtersToUrl(filter: APIFilterOption<ReadDTO>[] = []): object {
    const filterOptions = {};
    for (const item of filter) {
      if (item.value !== null && item.value !== '' || (item.value !== null && Array.isArray(item.value) && item.value.length > 0)) {
        let str = item.value;
        if(item.exact) {
          str = '!' + item.value;
        }

        if(!Array.isArray(item.value)) {
          const split = (item.key as string).split('.');

          if(!item.value || split?.length === 1) {
            filterOptions[item.key as string] = str;
          } else {
            const split = (item.key as string).split('.');
            // build an object out of a string notation (for example: a.b.c: "value").
            filterOptions[split[0]] = BaseRestAPIService.strToObj(split, item.value, 1);
          }
        } else {
          filterOptions[item.key as string] = str;
        }
      }
    }

    return filterOptions;
  }
}

