import {Injectable, Type} from "@angular/core";
import {AppointmentService} from "@core/services/appointment.service";
import {
    BaseQuestionnaireTypeComponent
} from "../../pages/questionnaires/types/base-questionnaire-type/base-questionnaire-type.component";
import {
    GlaucomaQuestionnaireComponent
} from "../../pages/questionnaires/types/glaucoma-questionnaire/glaucoma-questionnaire.component";
import {ExamType} from "@util/types/models";
import {
    Rijges107QuestionnaireComponent
} from "../../pages/questionnaires/types/rijges107-questionnaire/rijges107-questionnaire.component";
import {
    Rijges095QuestionnaireComponent
} from "../../pages/questionnaires/types/rijges095-questionnaire/rijges095-questionnaire.component";
import {
    OptometricExaminationQuestionnaireComponent
} from "../../pages/questionnaires/types/optometric-examination-questionnaire/optometric-examination-questionnaire.component";
import {
    DryEyesQuestionnaireComponent
} from "../../pages/questionnaires/types/dry-eyes-questionnaire/dry-eyes-questionnaire.component";
import {
    VisualImpairmentComponent
} from "../../pages/questionnaires/types/visual-impairment-questionnaire/visual-impairment-questionnaire.component";
import {
    SpotsAndFlashesQuestionnaireComponent
} from "../../pages/questionnaires/types/spots-and-flashes-questionnaire/spots-and-flashes-questionnaire.component";
import {FormGroup} from "@angular/forms";

export interface Questionnaire {
    component: Type<BaseQuestionnaireTypeComponent>,
    examType: ExamType,
    inputs: { // Required variables for the component
        title: string,
        nextStepFunction?: () => void,
        identifier?: string,
        appointmentId?: string,
    }
}

@Injectable({
    providedIn: 'root'
})
export class QuestionnaireService {
    constructor() {
    }
    public currentFormGroup: FormGroup = null;

    public getQuestionnaires(): Questionnaire[] {
        return [
            {
                component: GlaucomaQuestionnaireComponent,
                examType: ExamType.GlaucomaScreening,
                inputs: {
                    title: 'Glaucoomscreening',
                    identifier: 'glaucoma-screening-pre-exam',
                }
            },
            {
                component: Rijges107QuestionnaireComponent,
                examType: ExamType.Rijges107,
                inputs: {
                    title: 'Rijbewijskeuring 107',
                    identifier: 'rijges-107-pre-exam',
                }
            },
            {
                component: Rijges095QuestionnaireComponent,
                examType: ExamType.Rijges095,
                inputs: {
                    title: 'Rijbewijskeuring 095',
                    identifier: 'rijges-095-pre-exam',
                }
            },
            {
                component: OptometricExaminationQuestionnaireComponent,
                examType: ExamType.OptometricExamination,
                inputs: {
                    title: 'Optometrisch onderzoek',
                    identifier: 'optometric-examination-pre-exam',
                }
            },
            {
                component: DryEyesQuestionnaireComponent,
                examType: ExamType.DryEyes,
                inputs: {
                    title: 'Droge ogen onderzoek',
                    identifier: 'sicca-pre-exam',
                }
            },
            {
                component: VisualImpairmentComponent,
                examType: ExamType.VisualImpairment,
                inputs: {
                    title: 'Visusdaling',
                    identifier: 'visual-impairment-pre-exam',
                }
            },
            {
                component: SpotsAndFlashesQuestionnaireComponent,
                examType: ExamType.SpotsAndFlashes,
                inputs: {
                    title: 'Vlekken en flitsen',
                    identifier: 'spots-and-flashes-pre-exam',
                }
            }
        ];
    }
}
