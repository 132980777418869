import {Component, inject, ViewChild} from '@angular/core';
import {ExamTypeDropdown} from "@util/types/dropdowns";
import {ExamType, FormInput, FormInputType} from "@util/types/models";
import {FormBuilder} from "@angular/forms";
import {StepService} from "@core/services/step.service";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {PatientService} from "@core/services/patient.service";
import {firstValueFrom, Observable} from 'rxjs';
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {HotToastService} from "@ngneat/hot-toast";
import {
    ConfirmationDialogComponent
} from "@components/organisms/dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CanComponentDeactivate} from "@core/guards/can-deactivate.guard";

@Component({
  selector: 'app-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss']
})
export class QuestionnairesComponent implements CanComponentDeactivate {
    private questionnaireList = inject(QuestionnaireService).getQuestionnaires();
    public selectedQuestionnaire = null;
     public questionnaireTypeInput: FormInput = {
        type: FormInputType.Select,
        label: 'Selecteer een vragenlijst',
        key: 'type',
        selectOptions: ExamTypeDropdown.filter(option => [ExamType.Rijges107, ExamType.Rijges095, ExamType.OptometricExamination, ExamType.GlaucomaScreening, ExamType.DryEyes, ExamType.VisualImpairment, ExamType.SpotsAndFlashes].includes(option.value))
     };
     public typeForm = null;
     public showDropdown = environment.appEnv !== 'production';
     public defaultQuestionnaire = ExamType.SpotsAndFlashes;

     constructor(
         private formBuilder: FormBuilder,
         private activatedRoute: ActivatedRoute,
         private patientService: PatientService,
         private router: Router,
         private toastService: HotToastService,
         private dialog: MatDialog,
         private questionnaireService: QuestionnaireService
     ) {
         this.activatedRoute.queryParams.subscribe(async params => {
             if (params['id']) {
                 this.showDropdown = false;
                 const id = params['id'];
                 this.selectedQuestionnaire = this.questionnaireList.find((questionnaire) => questionnaire.inputs.identifier === id);
                 this.selectedQuestionnaire.inputs.appointmentId = params['appointmentId'];
                 const canAdd = await firstValueFrom(this.patientService.canAddQuestionnaire(id, this.selectedQuestionnaire.inputs.appointmentId));
                    if (!canAdd.can) {
                        this.router.navigate(['/dashboard']);
                        openToast(this.toastService, 'U mag deze vragenlijst niet (meer) invullen!', ToastType.Error);
                    }
             }

             if(!this.selectedQuestionnaire) {
                 this.typeForm = this.formBuilder.group({
                     type: [this.defaultQuestionnaire]
                 });

                 this.selectedQuestionnaire = this.questionnaireList.find((questionnaire) => questionnaire.examType === this.typeForm.value.type);
                 this.typeForm.valueChanges.subscribe((value) => {
                     this.selectedQuestionnaire = this.questionnaireList.find((questionnaire) => questionnaire.examType === value.type);
                 });
             }
         });
     }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.questionnaireService.currentFormGroup.dirty) {
            return new Promise<boolean>((resolve) => {
                const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        // showWarning: true,
                        title: 'Vragenlijst niet opgeslagen',
                        message: 'De vragenlijst wordt niet tussentijds opgeslagen, weet je zeker dat je de pagina wilt verlaten?',
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        resolve(result);
                    }
                });
            });
        }
        return true;
    }
}
