import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDropdownInputComponent } from './form-dropdown-input.component';
import {ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";



@NgModule({
    declarations: [
      FormDropdownInputComponent
    ],
    exports: [
      FormDropdownInputComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      FormErrorModule,
      NgSelectModule,
      FontAwesomeModule,
      FormErrorModule
    ]
})
export class FormDropdownInputModule { }
