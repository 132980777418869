import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormInputDirective} from "@util/directives/form-input/form-input.directive";



@NgModule({
  declarations: [
    FormInputDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
      FormInputDirective
  ]
})
export class DirectivesModule { }
