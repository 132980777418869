<ng-container formErrorContainer [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup">
  <div class="flex gap-3 zdWrapper errorWrapper">
    <mat-form-field class="formField" *ngFor="let i of inputArray; let idx = index">
      <div class="input-container">
        <input
          matInput
          [value]="inputValues[idx] ? inputValues[idx] : ''"
          [id]="input.key + idx"
          type="text"
          [maxLength]="1"
          (keyup)="handleKeydown($event, idx)"
          (keydown.backspace)="onDelete($event, idx)"
          [style.color]="inputType === 'password' ? 'white' : 'black'"
        />
      </div>
    </mat-form-field>
  </div>
</ng-container>

<form-error
    *ngIf="input.key === 'zdNumber' && zdNumberIncomplete"
    class="mt-2"
    [texts]="['Het ZD nummer is incompleet']"
/>

<!--<ng-container *ngIf="input.key === 'zdNumber' && formGroup.get(input.key).value">-->
<!--  <div class="feedback feedback&#45;&#45;valid" *ngIf="zdNumbersMatch">-->
<!--    <fa-icon [icon]="['far', 'check-circle']"></fa-icon>-->
<!--    De ZD nummers komen overeen.-->
<!--  </div>-->

<!--  <div class="feedback feedback&#45;&#45;invalid" *ngIf="!zdNumbersMatch">-->
<!--    <fa-icon [icon]="['far', 'times-circle']"></fa-icon>-->
<!--    De ZD nummers komen niet overeen.-->
<!--  </div>-->
<!--</ng-container>-->
