import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-form-dev-tools',
  templateUrl: './form-dev-tools.component.html',
  styleUrls: ['./form-dev-tools.component.scss']
})
export class FormDevToolsComponent implements OnInit {
  @Input({required: true}) form: FormGroup;
  @Input() autoFillValues;
  @Output() autoFillTriggered = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  checkEnvironment(): boolean {
    switch(environment.appEnv) {
      case 'local':
      case 'development':
        return true;
      default:
        return false;
    }

    return true;
  }

  logForm(): void {
    console.log(this.form);
  }

  logValues(): void {
    console.log(this.form.value);
  }

  autoFill(): void {
    let values;
    if (typeof this.autoFillValues === "function") {
      values = this.autoFillValues();
    } else {
      values = this.autoFillValues;
    }

    this.autoFillTriggered.emit();
    this.form.patchValue(values);
  }
}
