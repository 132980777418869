import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormsModule} from "@angular/forms";
import {BaseDialogModule} from "@components/organisms/dialogs/base-dialog/base-dialog.module";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";



@NgModule({
  declarations: [
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    BaseDialogModule,
    FontAwesomeModule,
    FormInputsModule,
    FormErrorModule,
    FormsModule,
    BaseButtonModule
  ]
})
export class ConfirmationDialogModule { }
