import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDialogComponent } from './base-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {IconButtonModule} from "@components/atoms/buttons/icon-button/icon-button.module";



@NgModule({
  declarations: [
    BaseDialogComponent
  ],
  exports: [
    BaseDialogComponent
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        FontAwesomeModule,
        BaseButtonModule,
        IconButtonModule
    ]
})
export class BaseDialogModule { }
