import { Component } from '@angular/core';
import {BaseButtonComponent} from "@components/atoms/buttons/base-button/base-button.component";

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent extends BaseButtonComponent {

  constructor() {
    super();
  }
}
