import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from "moment";
import {plainToInstance} from "class-transformer";
import {firstValueFrom, Observable} from "rxjs";
import {BaseRestAPIService} from "@core/services/base-rest-api.service";
import {SecurityService} from "@core/services/security.service";
import {environment} from "../../../environments/environment";
import {ExamType} from "@util/types/models";

moment.locale('nl');

// TODO: Fix typing
@Injectable({
  providedIn: 'root'
})
export class CompanyLocationService extends BaseRestAPIService<any, any, any> {
  get activeLocation(): any {
    return this._activeLocation;
  }

  set activeLocation(value: any) {
    this._activeLocation = value;
  }
  private _activeLocation: any;

  // FIXME: temp solution to get rijges grouped, fix this in the backend!
  get examinationTypes() {
    return this._activeLocation?.availableExaminationTypes?.filter(e => e.name !== 'rijges-095' && e.name !== 'unknown').map(e => {
      if(e.name === 'rijges-107') {
        e.prettyName = 'Rijbewijs oogkeuring'
      }
      return e;
    });
  }

  private newRijgesPrice = null;
  set examinationTypes({value, date}) {
    if (date) {
      value.forEach(type => {
        if (type.name === ExamType.Rijges107) {
          const isOnOrAfterApril1st = moment(date).isSameOrAfter(moment('2025-04-01'));
          !this.newRijgesPrice ? this.newRijgesPrice = type.price : undefined;
          type.price = isOnOrAfterApril1st ? this.newRijgesPrice : '114,00';

          // FIXME: TEMP!!
          if(!this._activeLocation.onlineAvailable) {
            type.price = '134,00';
          }
        }
      })
    }

    this._activeLocation.availableExaminationTypes = value;
  }

  // FIXME: TEMP!!
  setCallbackForm() {
    this._activeLocation.availableExaminationTypes.find(e => e.name === ExamType.Rijges107).price = '134,00';
  }

  constructor(http: HttpClient,
              securityService: SecurityService) {
    super(http,
      `${environment.apiUrl}/public/locations`,
      input => plainToInstance(CompanyLocationService,
        input
      ), securityService);
  }
}
