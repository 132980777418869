import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsComponent } from './appointments.component';
import {BasePageModule} from "../base-page/base-page.module";
import {SectionModule} from "@components/molecules/section/section.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {EditProfileDialogModule} from "@components/organisms/dialogs/edit-profile-dialog/edit-profile-dialog.module";
import {ChipModule} from "@components/atoms/chip/chip.module";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {MomentModule} from "ngx-moment";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    AppointmentsComponent
  ],
    imports: [
        CommonModule,
        BasePageModule,
        SectionModule,
        FontAwesomeModule,
        EditProfileDialogModule,
        ChipModule,
        LeafletModule,
        MomentModule,
        MatProgressSpinnerModule
    ]
})
export class AppointmentsModule { }
