import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, MaxLengthValidator, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AppointmentVerifyService} from "@core/services/appointment-verify.service";
import {AppointmentService} from "@core/services/appointment.service";
import {FormInput, FormInputType} from "@util/types/models";

@Component({
  selector: 'app-sms-confirmation-dialog',
  templateUrl: './sms-confirmation-dialog.component.html',
  styleUrls: ['./sms-confirmation-dialog.component.scss']
})
export class SmsConfirmationDialogComponent implements OnInit {
  public smsFormGroup: FormGroup;
  public codeInvalid = false;
  public smsInput: FormInput = {
    label: '',
    key: 'verificationCode',
    type: FormInputType.Text,
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SmsConfirmationDialogComponent>,
    private appointmentVerifyService: AppointmentVerifyService,
    private appointmentService: AppointmentService,
    @Inject(MAT_DIALOG_DATA) public data: {phoneNumber: string, appointmentId: string}
  ) { }

  ngOnInit(): void {
    this.smsFormGroup = this.formBuilder.group({
      verificationCode: ['', [Validators.required, Validators.maxLength(6), Validators.pattern('^[0-9]*$')]]
    });
  }

  save(): void {
    if (this.smsFormGroup.valid) {
      const smsCode = this.smsFormGroup.get('verificationCode').value;

      this.appointmentVerifyService.verify(this.appointmentService.appointmentId, smsCode).subscribe(result => {
        if (result) {
          this.codeInvalid = false;
          this.dialogRef.close(smsCode);
        } else {
          this.codeInvalid = true;
        }
      });
    }
  }
}
