<ng-container formErrorContainer [ngSwitch]="input.type" [formGroup]="formGroup">
  <label [for]="input.key" #labelElement class="form__label mb-1 block" *ngIf="showLabel">
    {{input.label}}

      <fa-icon
          *ngIf="input.tooltip"
          class="ml-2"
          [icon]="['fas', 'question-circle']"
          [matTooltip]="input.tooltip"
      ></fa-icon>
  </label>

  <ng-template appFormInput></ng-template>
</ng-container>
