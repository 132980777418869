import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit, ViewChild
} from '@angular/core';
import {faCircleExclamation, faExclamationTriangle} from "@fortawesome/pro-regular-svg-icons";

// TODO: Should refactor this a bit, as the new design wants every error shown as an icon, so all the checks can actually be removed.

@Component({
  selector: 'form-error',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {
  get questionKey(): string {
    return this._questionKey;
  }

  @Input() set questionKey(value: string) {
    this._questionKey = value;
  }
  faCircleExclamation = faCircleExclamation;
  faExclamationTriangle = faExclamationTriangle;
  _texts: string[];
  _hide = true;
  _labelElement;
  _popOverError = false;
  @Input() private _errorStyling;
  private _questionKey: string;

  @Input() set texts(value) {
    this._texts = value;
    this._hide = value?.length <= 0;
    this.cdr.detectChanges();
  }

  get texts() {
    return this._texts;
  }

  @Input() set labelElement(value) {
    this._labelElement = value;
    this.cdr.detectChanges();
  }

  @Input() set labelElementClass(value) {
    if(value === null && this._labelElement) {
      this._labelElement.classList.remove('form__label--error');
      this._labelElement.classList.remove('formPanel__formRow--error');
      return;
    }

    if(!this._hide && this._labelElement) {
      this._labelElement.classList.add(value);
    } else if(this._labelElement) {
      this._labelElement.classList.remove(value);
    }

    this.cdr.detectChanges();
  }

  set popOverError(value: boolean) {
    const parent = this.elRef.nativeElement.parentElement;
    const shouldShowPopover = parent.classList.contains('errorWrapper');
    if(shouldShowPopover) {
      this._popOverError = true;
    } else {
      this._popOverError = value;
    }
  }

  get popOverError(): boolean {
    return false;

    // if (this.elRef.nativeElement.offsetWidth <= 80) {
    //   return true;
    // }
    //
    // return this._popOverError;
  }

  get errorStyling() {
    return this._errorStyling;
  }

  set errorStyling(value) {
    this._errorStyling = value;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public elRef: ElementRef
  ) { }

  ngOnInit(): void {
  }
}
