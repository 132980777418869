import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './base-layout.component';
import {RouterOutlet} from "@angular/router";
import {SidebarModule} from "@components/templates/sidebar/sidebar.module";
import {TopbarModule} from "@components/templates/topbar/topbar.module";
import {FooterModule} from "@components/templates/footer/footer.module";

@NgModule({
    declarations: [
        BaseLayoutComponent
    ],
    exports: [
        BaseLayoutComponent
    ],
  imports: [
    CommonModule,
    RouterOutlet,
    SidebarModule,
    TopbarModule,
  FooterModule
  ]
})
export class BaseLayoutModule { }
