import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment.component';
import {AppointmentSummaryModule} from "@components/organisms/appointment-summary/appointment-summary.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
    SelectedLocationCardHeaderModule
} from "@components/atoms/selected-location-card-header/selected-location-card-header.module";
import {StepCardFooterModule} from "@components/molecules/step-card-footer/step-card-footer.module";
import {StepCardModule} from "@components/molecules/step-card/step-card.module";
import {TreatmentItemModule} from "@components/atoms/treatment-item/treatment-item.module";
import {PaymentItemModule} from "@components/atoms/payment-item/payment-item.module";
import {MatDividerModule} from "@angular/material/divider";



@NgModule({
  declarations: [
    PaymentComponent
  ],
    imports: [
        CommonModule,
        AppointmentSummaryModule,
        MatProgressSpinnerModule,
        SelectedLocationCardHeaderModule,
        StepCardFooterModule,
        StepCardModule,
        TreatmentItemModule,
        PaymentItemModule,
        MatDividerModule
    ]
})
export class PaymentModule { }
