import {Directive, ElementRef, ViewContainerRef} from '@angular/core';
import {FormTextInputComponent} from "@components/molecules/forms/types/form-text-input/form-text-input.component";
import {
  FormDropdownInputComponent
} from "@components/molecules/forms/types/form-dropdown-input/form-dropdown-input.component";
import {
  FormDatepickerInputComponent
} from "@components/molecules/forms/types/form-datepicker-input/form-datepicker-input.component";
import {FormRadioInputComponent} from "@components/molecules/forms/types/form-radio-input/form-radio-input.component";
import {
  FormSingleCheckboxInputComponent
} from "@components/molecules/forms/types/form-single-checkbox-input/form-single-checkbox-input.component";
import {
  FormTextareaInputComponent
} from "@components/molecules/forms/types/form-textarea-input/form-textarea-input.component";
import {FormInputType} from "@util/types/interfaces";
import {FormDobInputComponent} from "@components/molecules/forms/types/form-dob-input/form-dob-input.component";
import {
  FormCheckboxInputComponent
} from "@components/molecules/forms/types/form-checkbox-input/form-checkbox-input.component";
import {
  FormRadioNewlineInputComponent
} from "@components/molecules/forms/types/form-radio-newline-input/form-radio-newline-input.component";
import {FormZdInputComponent} from "@components/molecules/forms/types/form-zd-input/form-zd-input.component";

export const inputMap = {
  [FormInputType.Text]: FormTextInputComponent,
  [FormInputType.MultiChar]: FormZdInputComponent,
  [FormInputType.Select]: FormDropdownInputComponent,
  [FormInputType.DatePicker]: FormDatepickerInputComponent,
  [FormInputType.SingleRadio]: FormRadioInputComponent,
  [FormInputType.SingleCheckbox]: FormSingleCheckboxInputComponent,
  [FormInputType.Checkbox]: FormCheckboxInputComponent,
  [FormInputType.Textarea]: FormTextareaInputComponent,
  [FormInputType.DateOfBirth]: FormDobInputComponent,
  [FormInputType.SingleRadioNewline]: FormRadioNewlineInputComponent,
};

@Directive({
  selector: '[appFormInput]',
})
export class FormInputDirective {
  constructor(
      public elementRef: ElementRef,
      public viewContainerRef: ViewContainerRef,
  ) { }

}
