import {FormControl} from "@angular/forms";
import {environment} from "../../../environments/environment";

export function BSN11Validator(formControl: FormControl): { bsn11: boolean } | null {
    const bsn = formControl.value;
    if (!bsn) {
        return null;
    }

    let checksum = 0;

    for (let i = 0; i < 8; i++) {
        checksum += (9 - i) * Number(bsn[i]);
    }
    checksum -= Number(bsn[8]);

    if(environment.appEnv === 'local') {
        return null;
    }

    return ((checksum % 11) === 0) ? null : {bsn11: true};
}

// Generate a valid checksum
export function generateBSN(): number {
    let randomBSN;
    let sum;

    do {
        const rnd = Math.floor(Math.random() * 1000000000);
        randomBSN = addLeadingZerosToBSN(rnd);

        sum = getSumBSN(randomBSN);
    } while (sum % 11);

    return randomBSN;
}

function addLeadingZerosToBSN(bsn): string {
    return ("000000000" + bsn).slice(-9);
}

function getSumBSN(bsn): number {
    const a = parseInt(bsn[0])*9;
    const b = parseInt(bsn[1])*8;
    const c = parseInt(bsn[2])*7;
    const d = parseInt(bsn[3])*6;
    const e = parseInt(bsn[4])*5;
    const f = parseInt(bsn[5])*4;
    const g = parseInt(bsn[6])*3;
    const h = parseInt(bsn[7])*2;
    const i = parseInt(bsn[8])*-1;

    return a+b+c+d+e+f+g+h+i;
}
