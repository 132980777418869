import {Component, OnInit} from '@angular/core';
import {BaseLoginStepComponent} from "../base-login-step/base-login-step.component";
import {FormInput, FormInputType} from "@util/types/models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "@core/services/login.service";
import {firstValueFrom} from "rxjs";
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {HotToastService} from "@ngneat/hot-toast";

@Component({
  selector: 'app-confirm-phone-step',
  templateUrl: './confirm-phone-step.component.html',
  styleUrls: ['./confirm-phone-step.component.scss']
})
export class ConfirmPhoneStepComponent extends BaseLoginStepComponent implements OnInit {
  protected readonly FormInputType = FormInputType;
  public phoneInput: FormInput = {
    label: 'Laatste 4 cijfers van je mobiele nummer',
    type: FormInputType.Text,
    key: 'digits',
  };
  public loginForm: FormGroup;

  constructor(
      private formBuilder: FormBuilder,
      override loginService: LoginService,
      private toastService: HotToastService
  ) {
    super(loginService);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      digits: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });
  }

  async validate(): Promise<void> {
    if (this.loginForm.valid) {
      try {
        const verifyPhoneNr = await firstValueFrom(this.loginService.getSmsToken(this.loginService.loginToken, this.loginService.userId, this.loginForm.value.digits));
        if(!verifyPhoneNr) {
          throw new Error('Incorrect phone number!');
        }

        this.displayNextStep();
      } catch(e) {
        openToast(this.toastService, 'Incorrect telefoonnummer.', ToastType.Error);
        console.error(e);
      }
    }
  }
}
