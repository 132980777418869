import {Component} from "@angular/core";
import {BaseQuestionnaireTypeComponent, Section} from "../base-questionnaire-type/base-questionnaire-type.component";
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {FormBuilder} from "@angular/forms";
import {
    additionalInformation, dryEyesCorrectionQuestions, dryEyesEyeHealthQuestions, dryEyesGeneralHealthQuestions,
    explanation,
    glaucomaCorrectionQuestions, glaucomaEyeHealthQuestions,
    glaucomaGeneralHealthQuestions,
    SectionTitles
} from "../sharedQuestions";
import {SingleRadioInput} from "@util/types/interfaces";
import {PatientService} from "@core/services/patient.service";
import {HotToastService} from "@ngneat/hot-toast";
import {Router} from "@angular/router";

@Component({
    selector: 'app-dry-eyes-questionnaire',
    templateUrl: '../base-questionnaire-type/base-questionnaire-type.component.html',
})
export class DryEyesQuestionnaireComponent extends BaseQuestionnaireTypeComponent {
    constructor(
        override questionnairesService: QuestionnaireService,
        override formBuilder: FormBuilder,
        override patientService: PatientService,
        override toastService: HotToastService,
        override router: Router,
    ) {
        const rangeDropdown = [
            {name: '0', value: 0},
            {name: '1', value: 1},
            {name: '2', value: 2},
            {name: '3', value: 3},
            {name: '4', value: 4},
        ];
        const rangeNames = {left: 'Nooit', right: 'Altijd'};
        const rangeSub = 'Score: 0 (Nooit), 1 (Soms), 2 (De helft van de tijd), 3 (De meeste tijd), 4 (Altijd)';
        const sections: Section[] = [
            {
                title: SectionTitles.Complaint,
                inputs: [
                    explanation
                ],
            },
            {
                title: SectionTitles.GeneralHealth,
                inputs: dryEyesGeneralHealthQuestions
            },
            {
                title: SectionTitles.EyeCorrection,
                inputs: dryEyesCorrectionQuestions
            },
            {
                title: SectionTitles.EyeHealth,
                inputs: dryEyesEyeHealthQuestions
            },
            {
                title: 'In hoeverre heb je de afgelopen week last gehad van de onderstaande symptomen?',
                menuTitle: 'Jouw symptomen',
                subtitle: rangeSub,
                inputs: [
                    new SingleRadioInput('Lichtgevoelige ogen', 'light_sensitive_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Zandkorrel gevoel', 'sand_feeling_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Pijnlijke ogen', 'painful_eyes_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Wazig zien', 'blurry_vision_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Verminderd gezichtsvermogen', 'reduced_vision_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                ]
            },
            {
                title: 'Hebben je oogproblemen je afgelopen week beperkt in het uitvoeren van een of meerdere van de onderstaande activiteiten?',
                menuTitle: 'Activiteiten',
                subtitle: rangeSub,
                inputs: [
                    new SingleRadioInput('Lezen', 'reading_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Autorijden in het donker', 'driving_in_dark_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Werken achter de computer', 'computer_usage_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Televisie kijken', 'tv_watching_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                ]
            },
            {
                title: 'Hebben je ogen geïrriteerd of oncomfortabel aangevoeld in een van de onderstaande situaties gedurende de afgelopen week?',
                menuTitle: 'Situaties',
                subtitle: rangeSub,
                inputs: [
                    new SingleRadioInput('Fietsen of lopen in de wind', 'windy_conditions_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Ruimtes met een lage luchtvochtigheid', 'dry_environment_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                    new SingleRadioInput('Airconditioning', 'air_conditioning_score', rangeDropdown, 'horizontal', null, null, false, rangeNames),
                ]
            },
            {
                title: SectionTitles.Other,
                inputs: [additionalInformation]
            }
        ];

        super(questionnairesService, formBuilder, patientService, sections, toastService, router);
    }
}
