import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  public apiUrl = environment.apiUrl + '/public/availability';
  constructor(public http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }

  getSchedule(companyLocationId: string, examinationType: string, start: string, end: string): Observable<any> {
    const filter = {
      locationId: [companyLocationId],
      examinationType,
      start,
      end
    }

    return this.http.get<any>(`${this.apiUrl}/check?filter=${JSON.stringify(filter)}`).pipe(map(res => res[companyLocationId]));
  }

  getTimeslots(companyLocationId: string, examinationType: string, start: string, end: string): Observable<any> {
    const filter = {
      locationId: companyLocationId,
      examinationType,
      start,
      end
    }

    return this.http.get<any>(`${this.apiUrl}/timeslots?filter=${JSON.stringify(filter)}`).pipe(map(res => res?.items[0]));
  }
}
