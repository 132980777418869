import { Injectable } from "@angular/core";
import {BaseRestAPIService} from "@core/services/base-rest-api.service";
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@core/services/security.service";
import {environment} from "../../../environments/environment";
import {plainToInstance} from "class-transformer";
import {Observable} from "rxjs";
import {Patient} from "@util/types/models";

@Injectable({
    providedIn: 'root'
})
export class PatientService extends BaseRestAPIService<Patient, any, any>{
    constructor(http: HttpClient,
                securityService: SecurityService) {
        super(http,
            `${environment.clientBackendUrl}/patient`,
            input => plainToInstance(Patient,
                input
            ), securityService);
        }

    getMyPatient(): Observable<Patient> {
        return this.http.get<Patient>(`${this.apiUrl}/me`, {
            headers: {
                Authorization: `Token ${this.securityService.accessToken}`
            }
        });
    }

    // TODO: TYPING!
    getMyAppointments(): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}/me/appointments`, {
            headers: {
                Authorization: `Token ${this.securityService.accessToken}`
            }
        });
    }

    getMyTasks(): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}/me/tasks`, {
            headers: {
                Authorization: `Token ${this.securityService.accessToken}`
            }
        });
    }

    updateMyPatient(patient: Patient): Observable<Patient> {
        return this.http.patch<Patient>(`${this.apiUrl}/me`, patient, {
            headers: {
                Authorization: `Token ${this.securityService.accessToken}`
            }
        });
    }

    saveQuestionnaire(data: { identifier: string, data: any }): Observable<Patient> {
        return this.http.post<Patient>(`${environment.clientBackendUrl}/questionnaires`, data, {
            headers: {
                Authorization: `Token ${this.securityService.accessToken}`
            }
        });
    }

    canAddQuestionnaire(identifier: string, appointmentId: string): Observable<any> {
        return this.http.get<any>(`${environment.clientBackendUrl}/questionnaires/can-add/${identifier}/${appointmentId}`, {
            headers: {
                Authorization: `Token ${this.securityService.accessToken}`
            }
        });
    }
}
