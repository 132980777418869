import { NgModule } from '@angular/core';
import {OtpComponent} from "@components/templates/otp/otp.component";
import {NgForOf} from "@angular/common";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {MatSnackBar} from "@angular/material/snack-bar";

@NgModule({
    exports: [
        OtpComponent
    ],
    imports: [
        NgForOf,
        BaseButtonModule
    ],
    declarations: [
        OtpComponent,
    ],
    providers: [
        MatSnackBar,
    ]
})
export class OtpModule {}
