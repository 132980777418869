import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailLoginStepComponent } from './email-login-step.component';
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    EmailLoginStepComponent
  ],
    imports: [
        CommonModule,
        BaseButtonModule,
        FontAwesomeModule,
        FormInputsModule,
        FormsModule
    ]
})
export class EmailLoginStepModule { }
