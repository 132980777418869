import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {
  EditProfileDialogComponent
} from "@components/organisms/dialogs/edit-profile-dialog/edit-profile-dialog.component";
import {FormInputType, InnerSection, ProfileSection} from "@util/types/interfaces";
import {PatientService} from "@core/services/patient.service";
import {Patient} from "@util/types/models";
import {firstValueFrom} from "rxjs";
import {enumValueToName} from "@util/types/enums";
import moment from "moment";
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {HotToastService} from "@ngneat/hot-toast";
import {
    FindHealthcareProviderDialogComponent
} from "@components/organisms/dialogs/find-healthcare-provider-dialog/find-healthcare-provider-dialog.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public sections: ProfileSection[] = [
    {
      title: 'Algemeen',
      innerSections: [
          {
            title: {label: 'Personalia'},
            inputs: [
                {label: 'Initialen', key: 'initials', type: FormInputType.Text, required: true},
                // {label: 'Voornaam', key: 'firstName', type: FormInputType.Text},
                {label: 'Achternaam', key: 'lastName', type: FormInputType.Text, required: true},
                {label: 'Geslacht', key: 'gender', type: FormInputType.SingleRadio, selectOptions: [{name: 'Man', value: 'male'}, {name: 'Vrouw', value: 'female'}, {name: 'Onbekend', value: 'unkown'}], required: true},
                {label: 'BSN-nummer', key: 'bsn', type: FormInputType.Text, required: true},
                {label: 'Geboortedatum', key: 'dateOfBirth', type: FormInputType.DateOfBirth, required: true},
            ],
            action: (inner: InnerSection) => this.editSection(inner)
          },
          {
            title: {label: 'Adresgegevens'},
            inputs: [
                {label: 'Straatnaam', key: 'streetName', formGroupName: 'address', type: FormInputType.Text, required: true},
                {label: 'Huisnummer', key: 'houseNr', formGroupName: 'address', type: FormInputType.Text, required: true},
                {label: 'Postcode', key: 'postalCode', formGroupName: 'address', type: FormInputType.Text, required: true},
                {label: 'Plaats', key: 'city', formGroupName: 'address', type: FormInputType.Text, required: true},
            ],
            action: (inner: InnerSection) => this.editSection(inner)
          },
          {
            title: {label: 'Contactgegevens'},
            inputs: [
                {label: 'Telefoonnummer', key: 'phoneNumber', type: FormInputType.Text, required: true},
                {label: 'Email', key: 'email', type: FormInputType.Text, required: true},
            ],
            action: (inner: InnerSection) => this.editSection(inner)
          },
      ]
    },
      // TODO: uncomment when implemented!
/*    {
      title: 'Zorgteam',
      innerSections: [
        {title: {label: 'Huisarts', icon: ['fas', 'user-doctor']}, inputs: [], action: (inner: InnerSection) => this.findHealthcareProvider(inner, 'generalPractitioner')},
        {title: {label: 'Apotheek', icon: ['fas', 'house-medical']}, inputs: [], action: (inner: InnerSection) => this.findHealthcareProvider(inner, 'pharmacy')},
      ],
    }*/
  ];

  patient: Patient;

  constructor(private dialog: MatDialog,
              private patientService: PatientService,
              private toast: HotToastService) {
  }

  async ngOnInit(): Promise<void> {
      this.getPatient();
  }

  async getPatient() {
    this.patient = await firstValueFrom(this.patientService.getMyPatient());
  }

  public editSection(section: InnerSection): void {
      this.dialog.open(EditProfileDialogComponent, {
        data: {
          section,
          patient: this.patient
        }
      }).afterClosed().subscribe(async (result) => {
        if (result) {
            try {
                this.patient = {...this.patient, ...result};
                await firstValueFrom(this.patientService.updateMyPatient(this.patient));
                openToast(this.toast, 'Gegevens aangepast!', ToastType.Success);
                await this.getPatient();
            } catch (e) {
                openToast(this.toast, 'Er is iets misgegaan, probeer het opnieuw.', ToastType.Error);
            }
        }
      });
  }

    getValue(input, value) {
      if (input.selectOptions) {
          return enumValueToName(input.selectOptions, value);
      } else if(input.key === 'dateOfBirth') {
        return moment(value).format('DD-MM-YYYY');
      } else {
        return value;
      }
    }

    findHealthcareProvider(inner: InnerSection, type: string) {
        const dialog = this.dialog.open(FindHealthcareProviderDialogComponent, {
            data: {
                type
            }
        });
    }
}
