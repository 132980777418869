import { Component } from '@angular/core';
import {BaseLoginStepComponent} from "../base-login-step/base-login-step.component";
import {LoginService} from "@core/services/login.service";

@Component({
  selector: 'app-check-email-login-step',
  templateUrl: './check-email-login-step.component.html',
  styleUrls: ['./check-email-login-step.component.scss']
})
export class CheckEmailLoginStepComponent extends BaseLoginStepComponent {
  constructor(
      override loginService: LoginService
  ) {
    super(loginService);
  }
}
