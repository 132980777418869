import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Address} from "@util/types/models";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PostalcodeService {
  public apiUrl = environment.apiUrl + '/public/postalcode';
  constructor(public http: HttpClient) { }

  find(postalCode: string, houseNr: string): Observable<Address> {
    return this.http.get<Address>(this.apiUrl + `/${postalCode}/${houseNr}`);
  }
}
