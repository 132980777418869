import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppointmentVerifyService {

  constructor(private http: HttpClient) {
  }

  requestSms(id: string, phoneNumber: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/public/appointment/${id}/send-verification-sms?phone=${encodeURIComponent(phoneNumber)}`, null);
  }

  verify(id: string, code: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/public/appointment/${id}/verify-appointment-sms`, code);
  }
}
