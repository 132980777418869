import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import {DynamicFormModule} from "@components/templates/dynamic-form/dynamic-form.module";
import {DataTableModule} from "@components/organisms/data-table/data-table.module";
import {BasePageModule} from "../base-page/base-page.module";
import {OtpModule} from "@components/templates/otp/otp.module";
import {SectionModule} from "@components/molecules/section/section.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {IconButtonModule} from "@components/atoms/buttons/icon-button/icon-button.module";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {ChipModule} from "@components/atoms/chip/chip.module";
import {MomentModule} from "ngx-moment";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    DashboardComponent
  ],
    imports: [
        CommonModule,
        DynamicFormModule,
        DataTableModule,
        BasePageModule,
        OtpModule,
        SectionModule,
        FontAwesomeModule,
        IconButtonModule,
        LeafletModule,
        ChipModule,
        MomentModule,
        MatProgressSpinnerModule
    ]
})
export class DashboardModule { }
