<div class="hidden lg:block bg"></div>
<div
    class="container relative mx-auto py-12 text-primary px-4 lg:px-0 z-20"
    [ngClass]="{'lg:w-1/2': centered}"
>
    <h1 *ngIf="title" class="text-2xl mb-6 font-bold text-deepsea-3">
        {{title}}
    </h1>

    <ng-content select="[header]"></ng-content>
    <ng-content></ng-content>
</div>
