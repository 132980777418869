import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {StepService} from "@core/services/step.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-step-card-footer',
  templateUrl: './step-card-footer.component.html',
  styleUrls: ['./step-card-footer.component.scss']
})
export class StepCardFooterComponent {
  public stepList = inject(StepService).getSteps();
  // get nextStepTitle() {
  //   if (this.stepService.currentStepIndex === this.stepList.length - 1) {
  //     return 'Afronden';
  //   }
  //   return this.stepList[this.stepService.currentStepIndex + 1]?.inputs?.title;
  // }
  // get previousStepTitle() {
  //   return this.stepList[this.stepService.currentStepIndex - 1]?.inputs?.title;
  // }

  get canProceed(): boolean {
    return this._canProceed;
  }

  @Input() set canProceed(value: boolean) {
    this._canProceed = value;
  }
  private _canProceed = true;
  @Input() proceedText = null;
  @Output() nextStep: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public stepService: StepService
  ) {}

  displayNextStep() {
    const nextStepFunction = this.stepService.currentStep.inputs.nextStepFunction;
    if (nextStepFunction) {
      nextStepFunction();
    }

    this.nextStep.emit();

    if(this.canProceed) {
      // Reset the current ad index back to `0` when we reach the end of an array.
      if (this.stepService.currentStepIndex === this.stepList.length) {
        return;
      }

      this.stepService.currentStepIndex++;
      window.scrollTo(0, 0);
    }
  }

  displayPreviousStep() {
    if (this.stepService.currentStepIndex === 0) {
      // return to location overview on website
      window.open(`${ environment.websiteUrl}/locaties`, '_blank');
      return;
    }

    // Reset the current ad index back to the last index when we reach the beginning of an array.
    if (this.stepService.currentStepIndex === -1) {
      return;
    }

    this.stepService.currentStepIndex--;
  }
}
