import {Component, EventEmitter, Output} from '@angular/core';
import * as moment from "moment/moment";
import {ScheduleService} from "@core/services/schedule.service";
import {StepService} from "@core/services/step.service";
import {CompanyLocationService} from "@core/services/company-location.service";
import {AppointmentService} from "@core/services/appointment.service";

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent {
  public currentWeek: {date: Date, isOpen: boolean, selected: boolean}[] = [];
  public currentMonth: string;
  public selectedDate: Date;
  public selectedTime: string;
  public timeSlots = [];
  public nextAvailability: Date = undefined;
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  public canGoBack: boolean = false;

  constructor(
      private scheduleService: ScheduleService,
      public stepService: StepService,
      public locationService: CompanyLocationService,
      private appointmentService: AppointmentService
  ) {
    if (this.appointmentService.selectedDate) {
      this.selectedDate = this.appointmentService.selectedDate;
      this.goToDate(this.selectedDate);
    } else {
      this.constructWeek();
    }
  }

  constructWeek(direction?: 'previous' | 'next', startOfWeek?: Date) {
    const dateToUse = startOfWeek ? startOfWeek : this.currentWeek[0]?.date ?? new Date();
    const startOfThisWeek = moment(dateToUse).startOf('week');

    if (direction === 'previous') {
      if (!this.canGoBack) {
          return;
      }

      startOfThisWeek.subtract(7, 'days');
    }

    if (direction === 'next') {
      startOfThisWeek.add(7, 'days');
      this.canGoBack = true;
    }

    this.canGoBack = startOfThisWeek.isAfter(moment().startOf('week'));
    this.currentWeek = [];
    this.currentMonth = startOfThisWeek.format('MMMM');
    for (let i = 0; i < 7; i++) {
      const date = moment(startOfThisWeek).add(i, 'days').toDate();
      const isInThePast = moment(date).isBefore(moment().startOf('day'));
      const openingHours = this.locationService.activeLocation.openingHours;
      this.currentWeek.push({
        date,
        isOpen: !openingHours[Object.keys(openingHours)[i]].closed && !isInThePast,
        selected: false
      });
    }

    // if all days are closed, go to next week.
    // FIXME: make sure we don't get stuck in a loop when we get the actual open days from location!
    if(!this.currentWeek.find(day => day.isOpen)) {
      console.log('No open days in this week, go to next week');
      return this.constructWeek('next', this.currentWeek[6].date);
    }

    if (this.appointmentService.selectedDate) {
      this.selectedDate = this.appointmentService.selectedDate;
      this.selectDate(this.currentWeek.find(day => day.date.toDateString() === this.selectedDate.toDateString()));
    } else {
      this.selectDate(this.currentWeek.find(day => day.isOpen));
    }

    if (this.appointmentService.selectedTime) {
      this.selectedTime = this.appointmentService.selectedTime;
    }
  }

  selectDate(day: {date: Date, isOpen: boolean, selected?: boolean}) {
    if (!day?.isOpen) {
      return;
    }

    this.currentWeek.forEach(d => d.selected = false);
    day.selected = true;

    this.selectedDate = day.date;
    this.setDateTime({
      date: this.selectedDate,
      time: this.selectedTime
    });

    const start = moment(this.selectedDate).startOf('day');
    const end = moment(this.selectedDate).endOf('day');
    const examType = this.stepService.selectedExamType.name;
    this.scheduleService.getTimeslots(
        this.locationService.activeLocation.id,
        examType,
        start.toISOString(),
        end.toISOString()
    ).subscribe(response => {
      // this.timeSlots = response.map(entry => ({
      //   time: moment(entry.start).format('HH:mm'),
      //   disable: false
      // }))
      this.timeSlots = response?.entries?.map(slot => moment(slot.start).format('HH:mm'));
      if (response?.nextAvailability) {
        this.nextAvailability = moment(response?.nextAvailability).toDate().toString() === 'Invalid Date' ? undefined : moment(response?.nextAvailability).toDate();
      }
    });
  }

  goToDate(date?: Date) {
    const next = moment(date ?? this.nextAvailability).format('YYYY-MM-DD');
    this.constructWeek(undefined, moment(next).toDate());
    const day = this.currentWeek.find(day => moment(day.date).format('YYYY-MM-DD') === next);
    this.selectDate(day);
  }

  selectTime(time: string) {
    this.selectedTime = time;
    this.setDateTime({
      date: this.selectedDate,
      time: this.selectedTime
    });
  }

  setDateTime(data: { date: Date, time: string }) {
    this.appointmentService.selectedDate = data.date;
    this.appointmentService.selectedTime = data.time;
  }
}
