import {Component, HostListener, Inject, Input} from '@angular/core';
import {QuestionnaireService} from "@core/services/questionnaire.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormInput, FormInputType, FormSelectInput} from "@util/types/interfaces";
import {guard} from '@ucast/mongo2js';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PatientService} from "@core/services/patient.service";
import {firstValueFrom} from "rxjs";
import {openToast, ToastType} from "@util/helpers/toastHelper";
import {HotToastService} from "@ngneat/hot-toast";
import {Router} from "@angular/router";

export interface Section {
  title: string;
  menuTitle?: string;
  subtitle?: string;
  inputs: FormSelectInput[];
  hasErrors?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-base-questionnaire-type',
  templateUrl: './base-questionnaire-type.component.html',
  styleUrls: ['./base-questionnaire-type.component.scss']
})
export class BaseQuestionnaireTypeComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() identifier: string;
  @Input() appointmentId: string;
  public sections: Section[]  = []
  public activeSection = null;
  public saving = false;
  public questionnaireFormGroup: FormGroup;
  // @Inject(HotToastService) toastService: HotToastService;
  // @Inject(Router) router: Router;

  constructor(
      protected questionnairesService: QuestionnaireService,
      protected formBuilder: FormBuilder,
      protected patientService: PatientService,
      @Inject('sectionData') protected sectionData: Section[],
      protected toastService: HotToastService,
      protected router: Router,
) {
    this.sections = sectionData;
    this.setupForm();
    this.setActiveSection();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.setActiveSection();
  }

  setActiveSection() {
    const sections = document.querySelectorAll('section');

    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      const topbarHeight = document.querySelector('.topbar')?.getBoundingClientRect().height || 0;
      if (rect.top <= (topbarHeight + 100) && rect.bottom >= 0) {
        this.activeSection = section.id;
      }

      // If scroll is at bottom of page, set last section as active.
      // Using Math.Ceil and Math.Floor due to sub-pixel rendering, usually the cause of high-DPI screens.
      if (Math.ceil(window.innerHeight + window.scrollY) >= Math.floor(document.body.scrollHeight)) {
        this.activeSection = sections[sections.length - 1].id;
      }
    });
  }

  isArray(input: FormInput | FormSelectInput | FormInput[]): input is FormInput[] {
    return Array.isArray(input);
  }

  public setupForm() {
    this.activeSection = this.sections[0].title;
    const controls = this.sections.reduce((acc, section) => {
      section.hasErrors = null;

      section.inputs.forEach(input => {
        if (input.type === FormInputType.Checkbox) {
          const formArrayControls = input.selectOptions.reduce((arrayAcc, option) => {
            arrayAcc[option.value] = new FormControl(false);
            return arrayAcc;
          }, {});
          acc[input.key] = new FormGroup(formArrayControls);
        } else {
          acc[input.key] = new FormControl('');
        }

        input.required !== false ? acc[input.key].setValidators([Validators.required]) : null;

        // acc[input.key].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
        //   section.hasErrors = !this.allInputsCorrect(section);
        // });
      });
      return acc;
    }, {});

    // TODO: Add functionality that disables/hides inputs based on other inputs.
    this.questionnaireFormGroup = this.formBuilder.group(controls);
    this.questionnairesService.currentFormGroup = this.questionnaireFormGroup;

    this.checkRequirements();
    this.questionnaireFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.checkRequirements();
    });
  }

  checkRequirements(): void {
    this.sections.forEach((section) => {
      section.inputs.forEach((input) => {
        input.visible = this.checkIfVisible(input);
      });
    });
  }

  save() {
    if (this.questionnaireFormGroup.invalid) {
      this.sections.forEach(section => {
        section.hasErrors = !this.allInputsCorrect(section);
      });

      // Scroll to first .mat-mdc-form-field-error
      setTimeout(() => {
        const errorElement = document.querySelector('.mat-mdc-form-field-error');
        if (errorElement) {
          errorElement.scrollIntoView({behavior: 'smooth'});
        }
      }, 200)

      return;
    }

    this.saving = true;
    this.saveData();
  }

  async saveData() {
    try {
      const data = this.questionnaireFormGroup.value;
      const dto = {
        identifier: this.identifier,
        appointmentId: this.appointmentId,
        data
      }
      await firstValueFrom(this.patientService.saveQuestionnaire(dto));
      openToast(this.toastService, 'Vragenlijst opgeslagen!', ToastType.Success);
      this.saving = false;
      setTimeout(() => {
        this.questionnaireFormGroup.markAsPristine();
        this.router.navigate(['/dashboard']);
      })
    } catch(e) {
      console.error(e);
      openToast(this.toastService, 'Er ging iets fout!', ToastType.Error);
      this.saving = false;
    }
  }

  // TODO: Only run this when input is changed.
  allInputsCorrect(section) {
    return section.inputs.every(input => {
      if (this.isArray(input)) {
        return input.every(subInput => {
          if (!subInput.visible) return true;
          return this.questionnaireFormGroup.get(subInput.key).valid;
        });
      }
      if (!input.visible) return true;
      return this.questionnaireFormGroup.get(input.key).valid;
    });
  }

  checkIfVisible(input: FormSelectInput): boolean {
    if (!input.check) return true;

    const objs = {
      form: this.questionnaireFormGroup.getRawValue(),
      input,
    };

    const guard_ = guard(input.check);
    const isVisible = guard_(objs);
    if (input["log"]) {
      console.log(input.key, objs, input.check, guard_.ast, isVisible);
    }

    if (!isVisible) {
      this.questionnaireFormGroup.get(input.key).disable({emitEvent: false});
      this.questionnaireFormGroup.get(input.key).reset('', { emitEvent: false });
    } else {
      this.questionnaireFormGroup.get(input.key).enable({emitEvent: false});
    }

    return isVisible;
  }

  scrollToSection(section: string) {
    const element = document.getElementById(section);
    element.scrollIntoView({behavior: 'smooth'});
  }
}
