import {ChangeDetectorRef, Component, Input, OnInit, Renderer2} from '@angular/core';
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";
import {FormSelectInput} from "@util/types/interfaces";

@Component({
  selector: 'app-form-radio-input',
  templateUrl: './form-radio-input.component.html',
  styleUrls: ['./form-radio-input.component.scss']
})
export class FormRadioInputComponent extends BaseFormInputComponent implements OnInit {
  currentCheckedValue = null;
  @Input() withBorder= true;
  override input: FormSelectInput;

  constructor(private ren: Renderer2, protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }
  ngOnInit(): void {

  }

  checkState(el) {
    setTimeout(() => {
      if (this.currentCheckedValue && this.currentCheckedValue === el.value) {
        el.checked = false;
        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-program-focused');
        this.currentCheckedValue = null;
        this.formGroup.get(this.input.key).setValue(null);
      } else {
        this.currentCheckedValue = el.value
        this.formGroup.get(this.input.key).setValue(el.value);
      }
    })
  }
}
