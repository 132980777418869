import {Validators} from '@angular/forms';

export const ValidatorPatterns = {
  letters: {
    pattern: '^[a-zA-ZäëïöüáéíóúÄËÏÖÜÁÉÍÓÚñçÑÇ  -]*$',
    error: 'Dit veld mag alleen letters bevatten'
  },
  initials: {
    pattern: '^[A-Z-ÄËÏÖÜÁÉÍÓÚÑÇ.]*$',
    error: 'Dit veld mag alleen hoofdletters en punten bevatten'
  },
  middleName: {
    pattern: "^[a-zA-ZäëïöüáéíóúÄËÏÖÜÁÉÍÓÚñçÑÇ' ]*$",
    error: 'Dit veld bevat ongeldige tekens'
  },
  numbers: {
    pattern: '^[0-9]*$',
    error: 'Dit veld mag alleen nummers bevatten'
  },
  zdNumber: {
    pattern: '^[a-zA-Z0-9]{10}$',
    error: 'Deze velden mogen alleen nummers en letters bevatten'
  },
  postal: {
    pattern: '^\\d{4}\\s?\\w{2}$',
    error: 'Dit is geen valide postcode. Voorbeeld: 1234AB of 1234 AB'
  },
  date: {
    pattern: '^([0-2][0-9]|(3)[0-1])(\\-)(((0)[0-9])|((1)[0-2]))(\\-)\\d{4}$',
    error: 'Dit veld moet een datum bevatten'
  },
  email: {
    pattern: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$',
    error: 'Dit is geen valide e-mail adres. Voorbeeld: jan@alleman.nl'
  },
  phone: {
    pattern: '^((\\+31|0)[1-9][0-9]{0,2}\\s?-?\\s?[1-9](\\s?[0-9]{3,4}){1,2}|(\\+31|0)6\\s?-?\\s?[1-9]\\s?[0-9]\\s?[0-9]{2}\\s?[0-9]{2}\\s?[0-9]{2})$',
    error: 'Dit is geen valide telefoonnummer. Voorbeeld: 0201234567, 0612345678'
  },
  mobilePhone: {
    pattern: '^(?:06\\d{8}|(?:\\+|00)[1-9]\\d{0,2}0?\\d{7,11})$',
    error: 'Dit is geen valide mobiel 06-nummer. Voorbeeld: +31612345678 | 0031612345678 | 0612345678'
  },
  vatNL: {
    pattern: '^(NL)?[0-9]{9}B[0-9]{2}$',
    error: 'Dit is geen valide BTW-nummer. Voorbeeld: NL001234567B01'
  },
  eightDigitNumber: {
    pattern: '^[0-9]{8}$',
    error: 'Dit veld moet een nummer van acht (8) cijfers bevatten'
  },
  nineDigitNumber: {
    pattern: '^[0-9]{9}$',
    error: 'Dit veld moet een nummer van negen (9) cijfers bevatten'
  },
  contractPrice: {
    pattern: '^(€ )?(100(?:[\\.,]00?)?|\\d?\\d(?:[\\.,]\\d\\d?)?)$',
    error: 'Bedrag tussen de 0 en 100 euro met maximaal twee decimalen'
  },
  lifelineNumber: {
    pattern: '^[0-9]{9}(@lms.lifeline.nl)$',
    error: 'Dit is geen geldig lifeline nummer. Voorbeeld: 123456789@lms.lifeline.nl'
  },
  BIGNumber: {
    pattern: '^[0-9]{11}$',
    error: 'Dit is geen geldig BIG-nummer. Voorbeeld: 00123456789 (11 cijfers). Bestaat uw nummer uit 10 of 9 cijfers voeg dan 1 respectievelijk 2 keer een nul toe voor het nummer.'
  },
  bsn11: {
    pattern: 'bsn',
    error: 'test'
  },
  streetNameNL: {
    pattern: '([A-Za-z0-9\\-\\ ]+)',
    error: 'Straatnaam bevat ongeldige tekens'
  },
  cityNL: {
    pattern: '([A-Za-z0-9\ \'\-]+)',
    error: 'Woonplaats bevat ongeldige tekens'
  },
  time: {
    pattern: '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$',
    error: 'Incorrect tijdformat. Voorbeeld: 12:00'
  },
  iban: {
    pattern: '^([A-Z]{2}\\d{2}[A-Z0-9]{10,30})$',
    error: 'Ongeldig IBAN nummer. Voorbeeld: NL91ABNA0417164300'
  },
  docNr: {
    pattern: '^[A-NP-Za-np-z0-9]{9}$',
    error: 'Ongeldig documentnummer: 9 tekens, letter O kan niet gebruikt worden'
  },
  driversLicenseNr: {
    pattern: '^[0-9]{10}$',
    error: 'Ongeldig rijbewijsnummer'
  },
};


export function patternToError(pattern): string {
  const key = Object.keys(ValidatorPatterns).find(k => ValidatorPatterns[k].pattern === pattern.requiredPattern);
  return key ? ValidatorPatterns[key].error : 'Veld bevat ongeldige tekens';
}

export const LettersValidator = Validators.pattern(ValidatorPatterns.letters.pattern);
export const MiddleNameValidator = Validators.pattern(ValidatorPatterns.middleName.pattern);
export const NumbersValidator = Validators.pattern(ValidatorPatterns.numbers.pattern);
export const PostcodeValidator = Validators.pattern(ValidatorPatterns.postal.pattern);
export const DateValidator = Validators.pattern(ValidatorPatterns.date.pattern);
export const EmailValidator = Validators.pattern(ValidatorPatterns.email.pattern);
export const PhoneValidator = Validators.pattern(ValidatorPatterns.phone.pattern);
export const MobilePhoneValidator = Validators.pattern(ValidatorPatterns.mobilePhone.pattern);
export const ContractPriceValidator = Validators.pattern(ValidatorPatterns.contractPrice.pattern);
export const InitialsValidator = Validators.pattern(ValidatorPatterns.initials.pattern);
export const BSNValidator = Validators.pattern(ValidatorPatterns.nineDigitNumber.pattern);
export const CityValidator = Validators.pattern(ValidatorPatterns.cityNL.pattern);
export const StreetNameValidator = Validators.pattern(ValidatorPatterns.streetNameNL.pattern);
export const TimeValidator = Validators.pattern(ValidatorPatterns.time.pattern);
export const VATNLValidator = Validators.pattern(ValidatorPatterns.vatNL.pattern);
export const IBANValidator = Validators.pattern(ValidatorPatterns.iban.pattern);
export const DocNrValidator = Validators.pattern(ValidatorPatterns.docNr.pattern);
export const DriversLicenseNrValidator = Validators.pattern(ValidatorPatterns.driversLicenseNr.pattern);
