<div
  formErrorContainer
  [questionKey]="input.key"
  class="errorWrapper"
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
>
<!--  // [class.flex-col]="input.orientation === 'vertical'"-->
  <mat-radio-group
    [formControlName]="input.key"
    class="flex gap-8"
  >
      <ng-container *ngFor="let option of input.selectOptions">
          <mat-radio-button [value]="option.value">
              {{ option.name }}
          </mat-radio-button>
          <br />
      </ng-container>
  </mat-radio-group>
</div>
