export const environment = {
  production: false,
  version: 'v1',
  apiUrl: 'https://api.mijn.okulus.vqtech.nl/v1',
  clientBackendUrl: 'https://api.mijn.okulus.vqtech.nl/v1',
  authAuthority: '',
  authClientId: '',
  appEnv: 'development',
  skipAuthentication: true,
  devWebhookUrl: 'https://e1fd-46-244-5-154.eu.ngrok.io',
  appUrl: 'https://mijn.okulus.vqtech.nl',
  websiteUrl: 'https://okulus-site.vqtech.nl/',
  googleTagManagerId: 'GTM-MTW29TWD'
};
