import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputsComponent } from './form-inputs.component';
import {FormTextInputModule} from "@components/molecules/forms/types/form-text-input/form-text-input.module";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {ReactiveFormsModule} from "@angular/forms";
import {
  FormDropdownInputModule
} from "@components/molecules/forms/types/form-dropdown-input/form-dropdown-input.module";
import {
    FormTextareaInputModule
} from "@components/molecules/forms/types/form-textarea-input/form-textarea-input.module";
import {FormRadioInputModule} from "@components/molecules/forms/types/form-radio-input/form-radio-input.module";
import {
  FormSingleCheckboxInputModule
} from "@components/molecules/forms/types/form-single-checkbox-input/form-single-checkbox-input.module";
import {
  FormDatepickerInputModule
} from "@components/molecules/forms/types/form-datepicker-input/form-datepicker-input.module";
import {DirectivesModule} from "@util/directives/directives.module";
import {FormDobInputModule} from "@components/molecules/forms/types/form-dob-input/form-dob-input.module";
import {
    FormCheckboxInputModule
} from "@components/molecules/forms/types/form-checkbox-input/form-checkbox-input.module";
import {
    FormRadioNewlineInputModule
} from "@components/molecules/forms/types/form-radio-newline-input/form-radio-newline-input.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FormZdInputModule} from "@components/molecules/forms/types/form-zd-input/form-zd-input.module";

@NgModule({
    declarations: [
        FormInputsComponent
    ],
    exports: [
        FormInputsComponent
    ],
    imports: [
        CommonModule,
        FormTextInputModule,
        FormErrorModule,
        ReactiveFormsModule,
        FormDropdownInputModule,
        FormTextareaInputModule,
        FormRadioInputModule,
        FormSingleCheckboxInputModule,
        FormDatepickerInputModule,
        FormDobInputModule,
        FormCheckboxInputModule,
        FormRadioNewlineInputModule,
        DirectivesModule,
        FontAwesomeModule,
        MatTooltipModule,
        FormZdInputModule
    ],
    providers: [
        // {provide: DateAdapter, useClass: CustomDateAdapter},
        // { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' }
    ]
})
export class FormInputsModule { }
