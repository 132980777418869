import {Component, inject} from '@angular/core';
import {StepService} from "@core/services/step.service";
import {CompanyLocationService} from "@core/services/company-location.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(
      public stepService: StepService,
      public locationService: CompanyLocationService,
  ) {}

}
