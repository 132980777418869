import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDobInputComponent } from './form-dob-input.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {InputMaskModule} from "@ngneat/input-mask";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";


@NgModule({
    declarations: [
        FormDobInputComponent
    ],
    exports: [
        FormDobInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormErrorModule,
        ReactiveFormsModule,
        InputMaskModule,
        FontAwesomeModule
    ]
})
export class FormDobInputModule { }
