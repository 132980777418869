import { Component } from '@angular/core';
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {Keepalive} from "@ng-idle/keepalive";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Mijn Okulus - cliëntenportaal';

  idleState = null;
  timedOut = false
  lastPing?: Date = null;

  // TODO: only if logged in, so not when making an appointment.
  constructor(private idle: Idle, private keepalive: Keepalive) {
    return; // TODO: REMOVE WHEN IMPLEMENTED!
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(5);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => console.log("No longer idle!"));
    idle.onTimeout.subscribe(() => {
      console.log("Timeout!");
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // TODO: do a call to backend to logout the user.
    });

    idle.onIdleStart.subscribe(() => {
      console.log("You're idle!");
      // TODO: show dialog with warning.
    });

    idle.onTimeoutWarning.subscribe((countdown) =>  {
      console.log(`You will timeout in ${countdown} seconds`);
      // TODO: update dialog with live timer?
    });

    // sets the ping interval to 60 seconds
    keepalive.interval(60);

    keepalive.onPing.subscribe(() => {
      console.log("Keep alive ping!");
      // TODO: do a call to backend to keep the session alive.
      this.lastPing = new Date();
    });

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
