import { AbstractControl, ValidationErrors } from '@angular/forms';

export const MobilePhoneValidator = (control: AbstractControl): ValidationErrors | null => {
    const phoneValue: string = control.value;

    // If the phone field is empty, do not apply this validator.
    // Use the 'required' validator to enforce mandatory fields.
    if (!phoneValue) {
        return null;
    }

    // Define the regex pattern for validating mobile phone numbers
    const phoneRegex = /^((\+|00)[0-9]{2,5}(0)?6|06)[1-9][0-9]{7}$/;

    // Test the phone value against the regex
    const isValid = phoneRegex.test(phoneValue);

    // Return null if valid, otherwise return the validation error
    return isValid ? null : { mobileInvalid: true };
};
