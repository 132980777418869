import { NgModule } from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';
import {BasePageModule} from "../../../base-page/base-page.module";
import {AppointmentSummaryModule} from "@components/organisms/appointment-summary/appointment-summary.module";
import {StepCardModule} from "@components/molecules/step-card/step-card.module";
import {MatDividerModule} from "@angular/material/divider";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {RouterLink} from "@angular/router";



@NgModule({
  declarations: [
    ConfirmationComponent
  ],
    imports: [
        CommonModule,
        BasePageModule,
        AppointmentSummaryModule,
        StepCardModule,
        MatDividerModule,
        BaseButtonModule,
        FontAwesomeModule,
        MatProgressSpinnerModule,
        NgIf,
        NgIf,
        RouterLink
    ]
})
export class ConfirmationModule { }
