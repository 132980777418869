import {AbstractControl, ValidationErrors} from "@angular/forms";

export const SameEmailValidator = (control: AbstractControl): ValidationErrors | null => {
    const email = control.parent?.get('email');
    const emailRepeat = control;
    if (!email || !emailRepeat) {
        return null;
    }

    return email.value === emailRepeat.value ? null : {emailNotTheSame: true};
}
