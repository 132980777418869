import {Component, Inject, OnInit} from '@angular/core';
import {InnerSection} from "@util/types/interfaces";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Patient} from "@util/types/models";
import {BSNValidator, EmailValidator, PhoneValidator} from "@util/validators/validatorHelper";
import {BSN11Validator} from "@util/validators/bsnValidator";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {parseInitials} from "@util/helpers/name-helper";
import {BaseDialogComponent} from "@components/organisms/dialogs/base-dialog/base-dialog.component";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})
export class EditProfileDialogComponent extends BaseDialogComponent implements OnInit {
  public form: FormGroup;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: { section: InnerSection, patient: Patient },
      private formBuilder: FormBuilder,
      override dialogRef: MatDialogRef<EditProfileDialogComponent>
  ) {
    super(dialogRef);
  }

  override ngOnInit() {
    const controls = {};
    const inputsInGroup = this.data.section.inputs.filter(input => input.formGroupName);
    const otherInputs = this.data.section.inputs.filter(input => !input.formGroupName);
    inputsInGroup.forEach(input => {
      const groupControls = {};
      this.data.section.inputs.filter(i => i.formGroupName === input.formGroupName).forEach(i => {
          groupControls[i.key] = [''];
      });
      if (!controls[input.formGroupName]) {
          controls[input.formGroupName] = this.formBuilder.group(groupControls);
      }
    });

    otherInputs.forEach(input => {
      // FIXME: Define these elsewhere.
      const validators = input.required ? [Validators.required] : [];
      if (input.key === 'bsn') {
        validators.push(BSNValidator, BSN11Validator);
      }

      if (input.key === 'email') {
        validators.push(EmailValidator);
      }

      if (input.key === 'phoneNumber') {
        validators.push(PhoneValidator);
      }

      controls[input.key] = ['', validators];
    });

    this.form = this.formBuilder.group(controls);
    this.form.patchValue(this.data.patient);

    if (this.data.patient.bsn) {
      this.form.get('bsn')?.disable();
    }

    this.form.get('initials')?.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1500), untilDestroyed(this))
      .subscribe(value => {
        if (value) {
          const parsed = parseInitials(value);
          this.form.get('initials').patchValue(parsed);
        }
      });
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
