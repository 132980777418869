import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatButtonModule} from "@angular/material/button";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatMenuModule} from "@angular/material/menu";
// import {PaginatorModule} from "../paginator/paginator.module";
// import {StatusTagDirective} from "../../../core/directives/status-tag/status-tag.directive";
// import {OverviewSearchBarModule} from "@modules/shared/overview-search-bar/overview-search-bar.module";

@NgModule({
  declarations: [
      DataTableComponent,
      // StatusTagDirective
  ],
  exports: [
    DataTableComponent,
    // StatusTagDirective
  ],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        FontAwesomeModule,
        MatMenuModule,
        // PaginatorModule,
        // OverviewSearchBarModule
    ]
})
export class DataTableModule { }
