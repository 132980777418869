import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDateTimeComponent } from './select-date-time.component';
import {StepCardModule} from "@components/molecules/step-card/step-card.module";
import {TreatmentItemModule} from "@components/atoms/treatment-item/treatment-item.module";
import {MatDividerModule} from "@angular/material/divider";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MomentModule} from "ngx-moment";
import {DateTimePickerModule} from "@components/molecules/date-time-picker/date-time-picker.module";
import {
    SelectedLocationCardHeaderModule
} from "@components/atoms/selected-location-card-header/selected-location-card-header.module";
import {AppointmentSummaryModule} from "@components/organisms/appointment-summary/appointment-summary.module";
import {StepCardFooterModule} from "@components/molecules/step-card-footer/step-card-footer.module";

@NgModule({
  declarations: [
    SelectDateTimeComponent
  ],
    imports: [
        CommonModule,
        StepCardModule,
        TreatmentItemModule,
        MatDividerModule,
        FontAwesomeModule,
        MomentModule,
        DateTimePickerModule,
        SelectedLocationCardHeaderModule,
        AppointmentSummaryModule,
        StepCardFooterModule
    ]
})
export class SelectDateTimeModule { }
