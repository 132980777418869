<div class="topbar">
  <div class="container mx-auto py-3 px-4 lg:px-0" *ngIf="!isMakingAppointment">
      <span class="cursor-pointer text-deepsea-1 hover:underline">
          <fa-icon class="mr-2" [icon]="['far', 'arrow-left-long']"></fa-icon>
          Terug naar website
      </span>
  </div>

    <ng-container *ngIf="!isLoggingIn">
        <div class="border-b border-grey-2"></div>
        <div class="container mx-auto py-6 text-white px-4 lg:px-0">
            <div class="flex text-body justify-between">
                <img class="cursor-pointer lg:mx-0" [routerLink]="'/'" src="assets/okulus-logo-db.svg">

                <ng-container *ngIf="!isMakingAppointment">
                    <div class="hidden lg:flex">
                        <ul class="flex gap-6">
                            <li
                                *ngFor="let link of links"
                                [routerLink]="link.url"
                                routerLinkActive="active"
                                class="px-2 py-3 cursor-pointer"
                            >
                                {{ link.label }}
                            </li>
                        </ul>
                    </div>

                    <a class="px-2 py-3 hidden lg:flex" href="#" (click)="logout()">
                      <span>
                        Uitloggen
                      </span>
                    </a>

                    <div class="flex lg:hidden">
                        <fa-icon (click)="menuOpen = true" *ngIf="!menuOpen" class="cursor-pointer" [icon]="['fas', 'bars']"></fa-icon>
                        <fa-icon (click)="menuOpen = false" *ngIf="menuOpen" class="cursor-pointer" [icon]="['far', 'times']"></fa-icon>
                    </div>
                </ng-container>
            </div>

            <div class="flex lg:hidden mt-4" *ngIf="menuOpen">
                <div class="flex">
                    <ul class="flex flex-col gap-4 text-body">
                        <li
                            *ngFor="let link of links"
                            (click)="menuOpen = false"
                            [routerLink]="link.url"
                            routerLinkActive="active"
                            class="px-2 py-2 cursor-pointer"
                        >
                            {{ link.label }}
                        </li>
                        <li>
                            <a class="px-2 py-2" href="#" (click)="logout()">
                              <span>
                                Uitloggen
                              </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="steps flex mt-8 justify-center lg:justify-normal" *ngIf="isMakingAppointment">
                <div *ngFor="let step of steps; let i = index; let last = last" class="step flex gap-2 items-center">
                    <div
                            class="step__number ml-2"
                            [class.step__number--active]="i === currentStep"
                            [class.step__number--done]="i < currentStep"
                            [ngClass]="{'hidden lg:flex': i > currentStep || i < currentStep}"
                    >
                        <div *ngIf="i < currentStep else stepNumber">
                            <fa-icon [icon]="['far', 'check']"></fa-icon>
                        </div>
                        <ng-template #stepNumber>
                            {{i + 1}}
                        </ng-template>
                    </div>
                    <div class="flex items-center gap-2" [ngClass]="{'hidden lg:flex': i > currentStep || i < currentStep}">
                        <div class="step-text">{{step.name}}</div>
                        <mat-divider
                                *ngIf="!last"
                                class="step__divider hidden lg:flex"
                                [class.step__divider--active]="i === currentStep"
                        />
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
