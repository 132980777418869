import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-payment-item',
  templateUrl: './payment-item.component.html',
  styleUrls: ['../treatment-item/treatment-item.component.scss', './payment-item.component.scss']
})
export class PaymentItemComponent {
  @Input({required: true}) method: any;
  @Input({required: true}) selectedMethod: any;
  @Output() selectMethod: EventEmitter<any> = new EventEmitter<any>();
}
