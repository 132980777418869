import {NgModule} from '@angular/core';
import {FormErrorHandlingDirective} from './form-error-handling.directive';
import {FormErrorContainerDirective} from './form-error-container.directive';
import {FormErrorSubmitDirective} from './form-error-submit.directive';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CommonModule} from '@angular/common';
import {FormErrorComponent} from "@components/molecules/forms/form-error/form-error.component";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    FormErrorHandlingDirective,
    FormErrorContainerDirective,
    FormErrorSubmitDirective,
    FormErrorComponent
  ],
    imports: [
        FontAwesomeModule,
        CommonModule,
        MatInputModule,
        MatTooltipModule
    ],
  exports: [
    FormErrorHandlingDirective,
    FormErrorSubmitDirective,
    FormErrorContainerDirective,
    FormErrorComponent
  ]
})
export class FormErrorModule {
}
