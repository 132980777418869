import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDatepickerInputComponent } from './form-datepicker-input.component';
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {CustomDateAdapter} from "@util/helpers/CustomDateAdapter";


@NgModule({
    declarations: [
        FormDatepickerInputComponent
    ],
    exports: [
        FormDatepickerInputComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        FormErrorModule,
        MatNativeDateModule
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' }
    ]
})
export class FormDatepickerInputModule { }
