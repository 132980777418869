import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";

@Component({
  selector: 'app-form-textarea-input',
  templateUrl: './form-textarea-input.component.html',
  styleUrls: ['./form-textarea-input.component.scss']
})
export class FormTextareaInputComponent extends BaseFormInputComponent implements OnInit {
  public allData;
  constructor(
    // private dialog: MatDialog,
    // private toast: HotToastService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.allData = {
      formGroup: this.formGroup,
      // dialog: this.dialog,
      // toast: this.toast,
    }
  }
}
