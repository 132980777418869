<app-button
  *ngIf="checkEnvironment()"
  [matMenuTriggerFor]="menu"
>
  <fa-icon [icon]="['far', 'code']"></fa-icon>
</app-button>

<mat-menu class="custom-mat-menu" #menu="matMenu">
  <button mat-menu-item (click)="logForm()">Log entire form</button>
  <button mat-menu-item (click)="logValues()">Log form values</button>
  <button [disabled]="!autoFillValues" mat-menu-item (click)="autoFill()">Auto fill</button>
</mat-menu>
