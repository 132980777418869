export function enumValueToName(arr: any[], value: string, icon = false, plural = false): any {
    const fnd = arr.find(val => val.value === value);
    if (fnd) {
        return icon ? fnd.icon : (plural ? fnd.pluralName || fnd.name : fnd.name);
    }

    return 'Niet gevonden';
}

export enum DateTimeNotations {
    DefaultDateNotation = 'DD-MM-YYYY',
    DefaultTimeNotation = 'HH:mm',
    DefaultCombinedNotation = 'DD-MM-YYYY | HH:mm'
}

export enum TableActionType {
    Add = 'add',
    Edit = 'edit',
    Delete = 'delete',
    Download = 'download',
}

export enum Gender {
    Male = 'male',
    Female = 'female',
    Other = 'other',
    Unknown = 'unknown',
}
