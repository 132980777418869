import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormDialogModule} from "@components/organisms/dialogs/form-dialog/form-dialog.module";
import {ConfirmationDialogModule} from "@components/organisms/dialogs/confirmation-dialog/confirmation-dialog.module";
import {FormEntryDialogModule} from "@components/organisms/dialogs/form-entry-dialog/form-entry-dialog.module";
import {
  FormEntryFieldDialogModule
} from "@components/organisms/dialogs/form-entry-field-dialog/form-entry-field-dialog.module";
import {
  SmsConfirmationDialogModule
} from "@components/organisms/dialogs/sms-confirmation-dialog/sms-confirmation-dialog.module";
import {
  FindHealthcareProviderDialogModule
} from "@components/organisms/dialogs/find-healthcare-provider-dialog/find-healthcare-provider-dialog.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormDialogModule,
    FormEntryDialogModule,
    ConfirmationDialogModule,
    FormEntryFieldDialogModule,
    SmsConfirmationDialogModule,
    FindHealthcareProviderDialogModule
  ]
})
export class DialogsModule { }
