import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepCardComponent } from './step-card.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";



@NgModule({
    declarations: [
        StepCardComponent
    ],
    exports: [
        StepCardComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule
    ]
})
export class StepCardModule { }
