import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {
  EditProfileDialogComponent
} from "@components/organisms/dialogs/edit-profile-dialog/edit-profile-dialog.component";
import {FormInputType, InnerSection, ProfileSection} from "@util/types/interfaces";
import {PatientService} from "@core/services/patient.service";
import {Patient} from "@util/types/models";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
  patient: Patient;
  appointments: any[] = [];

  constructor(private dialog: MatDialog,
              private patientService: PatientService) {
  }

  async ngOnInit(): Promise<void> {
      this.getPatient();
  }

  async getPatient() {
      this.patient = await firstValueFrom(this.patientService.getMyPatient());
      const appointments = await firstValueFrom(this.patientService.getMyAppointments());
      this.appointments = appointments?.sort((a, b) => a.calendarEntry.start > b.calendarEntry.start ? 1 : -1) ?? [];
  }
}
