<div
  formErrorContainer
  [questionKey]="input.key"
  class="errorWrapper d-flex"
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
>
  <mat-checkbox [formControlName]="input.key" class="my-1">
    {{ input.label }}
  </mat-checkbox>
</div>
