import { Component } from '@angular/core';
import {CompanyLocationService} from "@core/services/company-location.service";
import {StepService} from "@core/services/step.service";
import {AppointmentService} from "@core/services/appointment.service";
import {parseAddress} from "@util/helpers/name-helper";
import {ExamType} from "@util/types/models";

@Component({
  selector: 'app-appointment-summary',
  templateUrl: './appointment-summary.component.html',
  styleUrls: ['./appointment-summary.component.scss']
})
export class AppointmentSummaryComponent {
  public examTypeTextMap = {
    [ExamType.GlaucomaScreening]: 'Bij een glaucoomscreening brengen we tijdens het gesprek risicofactoren in kaart. We doen een uitgebreid oogonderzoek waarbij we de voor- en achterkant van het oog met een microscoop bekijken. Uw zicht en oogdruk worden nauwkeurig gemeten. Met een geavanceerde scan beoordelen we de oogzenuw. Op indicatie kunnen we ook een gezichtsveld-onderzoek verrichten. Zo sporen we glaucoom vroegtijdig op.',
    [ExamType.Rijges095]: 'Als je door het CBR bent verwezen naar de oogarts, kun je een afspraak maken bij een van onze locaties bij jou in de buurt. Daar onderzoekt een optometrist uitgebreid je ogen, waarna deze het dossier digitaal deelt met de oogarts op afstand.',
    [ExamType.Rijges107]: 'Als je door het CBR bent verwezen naar de oogarts, kun je een afspraak maken bij een van onze locaties bij jou in de buurt. Daar onderzoekt een optometrist uitgebreid je ogen, waarna deze het dossier digitaal deelt met de oogarts op afstand.',
    [ExamType.FundusScreening]: 'Als je diabetes hebt, kun je netvliesafwijkingen in je ogen krijgen. Laat je ogen regelmatig checken. De oogzorgspecialist onderzoeket de achterkant van je ogen: het netvlies. Met een speciale camera maakt hij gedetailleerde foto’s van het netvlies.',
    [ExamType.OptometricExamination]: 'Een uitgebreide analyse van uw gezichtsvermogen en ooggezondheid, voor het identificeren van zichtproblemen en het bepalen van de optimale correctie.',
  }

  constructor(
      public stepService: StepService,
      public locationService: CompanyLocationService,
      public appointmentService: AppointmentService,
  ) {
  }

  ngOnInit(): void {

  }

  protected readonly parseAddress = parseAddress;
}
