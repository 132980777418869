import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePageComponent } from './base-page.component';
import {DynamicFormModule} from "@components/templates/dynamic-form/dynamic-form.module";



@NgModule({
  declarations: [
    BasePageComponent
  ],
  exports: [
    BasePageComponent
  ],
  imports: [
    CommonModule,
    DynamicFormModule
  ]
})
export class BasePageModule { }
