<div
  formErrorContainer
  [questionKey]="input.key"
  class="errorWrapper radioWrapper flex items-center gap-4"
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
>
    <div *ngIf="input.range" class="text-grey-3 text-sm">{{input.range.left}}</div>
  <mat-radio-group
    [formControlName]="input.key"
    class="flex gap-8"
    [class.flex-col]="input.orientation === 'vertical'"
  >
    <mat-radio-button
        *ngFor="let option of input.selectOptions"
        [value]="option.value"
        [class.isRange]="input.range"
    >
      {{ option.name }}
    </mat-radio-button>
  </mat-radio-group>
    <div *ngIf="input.range" class="text-grey-3 text-sm">{{input.range.right}}</div>
</div>
