import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {plainToInstance} from 'class-transformer';
import {Observable, of} from "rxjs";
import {APIFilterOption, BaseRestAPIService} from "@core/services/base-rest-api.service";
import {CreateHealthcareParty, HealthcareParty, UpdateHealthcareParty} from "@util/types/models";
import {SecurityService} from "@core/services/security.service";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HealthcarePartyService extends BaseRestAPIService<HealthcareParty, CreateHealthcareParty, UpdateHealthcareParty> {
    constructor(http: HttpClient,
                securityService: SecurityService,
    ) {
        super(
            http,
            `${environment.apiUrl}/healthcare-party`,
            input => plainToInstance(HealthcareParty, input),
            securityService);
    }

    // TODO: typing.
    search(query: string, filters: APIFilterOption<HealthcareParty>[] = []): Observable<any[]> {
        const filterOptions = this.filtersToUrl(filters);
        return this.http.get<any[]>(
            `${this.apiUrl}/search?query=${query}&filter=${JSON.stringify(filterOptions)}`, this.securityService.getHttpRequestOptions()
        );
    }
}
