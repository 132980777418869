import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindHealthcareProviderDialogComponent } from './find-healthcare-provider-dialog.component';
import {BaseDialogModule} from "@components/organisms/dialogs/base-dialog/base-dialog.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FormDevToolsModule} from "@components/molecules/forms/form-dev-tools/form-dev-tools.module";
import {ReactiveFormsModule} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {MatListModule} from "@angular/material/list";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";



@NgModule({
  declarations: [
    FindHealthcareProviderDialogComponent
  ],
    imports: [
        CommonModule,
        BaseDialogModule,
        BaseButtonModule,
        FormDevToolsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        FormInputsModule,
        MatListModule,
        MatProgressSpinnerModule,
        FormErrorModule
    ]
})
export class FindHealthcareProviderDialogModule { }
