import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsConfirmationDialogComponent } from './sms-confirmation-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {BaseDialogModule} from "@components/organisms/dialogs/base-dialog/base-dialog.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";

@NgModule({
  declarations: [
    SmsConfirmationDialogComponent
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDividerModule,
        MatButtonModule,
        MatInputModule,
        FormErrorModule,
        BaseDialogModule,
        BaseButtonModule,
        FormInputsModule
    ]
})
export class SmsConfirmationDialogModule { }
