<div
  formErrorContainer
  [questionKey]="input.key"
  class="errorWrapper d-flex"
  [formGroup]="input.formGroupName ? $any(formGroup.get(input.formGroupName)) : formGroup"
>
    <ng-container *ngFor="let inp of input.selectOptions">
        <mat-checkbox [formControlName]="inp.value" class="my-1">
            {{ inp.name }}
        </mat-checkbox>
        <br />
    </ng-container>
</div>
