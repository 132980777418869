import {Injectable, Type} from "@angular/core";
import {EmailLoginStepComponent} from "../../pages/login/steps/email-login-step/email-login-step.component";
import {Observable, Subject} from "rxjs";
import {
    CheckEmailLoginStepComponent
} from "../../pages/login/steps/check-email-login-step/check-email-login-step.component";
import {ConfirmPhoneStepComponent} from "../../pages/login/steps/confirm-phone-step/confirm-phone-step.component";
import {ValidateCodeStepComponent} from "../../pages/login/steps/validate-code-step/validate-code-step.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

export interface LoginStep {
    component: Type<any>,
    inputs: { // Required variables for the component
        title: string,
        subtitle: string,
        nextStepFunction?: () => void
    }
}

export class TokenValidationResult {
    success: boolean;
}

export class AccessTokenResult {
    success: boolean;
    accessToken: string;
}

// TODO: implement logic: if no token is provided, show the first step.
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    get currentStep(): LoginStep {
        return this.getLoginSteps()[this.currentStepIndex];
    }
    get currentStepIndex(): number {
        return this._currentStepIndex;
    }

    set currentStepIndex(value: number) {
        this._currentStepIndex = value;
        this.$currentStepIndex.next(value);
    }
    private _currentStepIndex = 0;
    public $currentStepIndex: Subject<any> = new Subject<any>();

    get loginToken(): string {
        return this._loginToken;
    }

    set loginToken(value: string) {
        this._loginToken = value;
    }
    private _loginToken = null;

    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }
    private _userId = null;

    private apiUrl = `${environment.clientBackendUrl}/login`;

    constructor(protected http: HttpClient) {
    }

    getMailToken(email: string): Observable<TokenValidationResult> {
        return this.http.post<TokenValidationResult>(
            `${this.apiUrl}/get-mail-token`,
            {
                email
            }
        );
    }

    validateMailToken(token: string, userId: string): Observable<TokenValidationResult> {
        return this.http.post<TokenValidationResult>(
            `${this.apiUrl}/verify-mail-token`,
            {
                token,
                userId
            }
        );
    }

    getSmsToken(token: string, userId: string, lastFourDigits: string): Observable<TokenValidationResult> {
        return this.http.post<TokenValidationResult>(
            `${this.apiUrl}/get-sms-token`,
            {
                token,
                userId,
                lastFourDigitsPhoneNr: lastFourDigits
            }
        );
    }

    verifySmsToken(code: string, userId: string): Observable<AccessTokenResult> {
        return this.http.post<AccessTokenResult>(
            `${this.apiUrl}/verify-sms-token`,
            {
                smsToken: code,
                userId
            }
        );
    }

    public getLoginSteps(): LoginStep[] {
        return [
            {
                component: EmailLoginStepComponent,
                inputs: {
                    title: 'Inloggen',
                    subtitle: 'Vul je e-mailadres in om een login link te ontvangen.'
                }
            },
            {
                component: CheckEmailLoginStepComponent,
                inputs: {
                    title: 'Controleer je e-mail',
                    subtitle: 'Je hebt een e-mail met een link om in te loggen ontvangen. Zie je de e-mail niet in je ‘postvak’ staan, kijk dan bij ‘ongewenst’. De mail is afkomstig van no-reply@okulus.nl.'
                }
            },
            {
                component: ConfirmPhoneStepComponent,
                inputs: {
                    title: 'Voer laatste 4 cijfers in',
                    // TODO: get actual part of phone number.
                    subtitle: 'Voer de laatste 4 cijfers van je mobiele nummer in waarmee je de afspraak geboekt hebt, en klik op ‘Verder’ om een 6-cijferige code te ontvangen.'
                }
            },
            {
                component: ValidateCodeStepComponent,
                inputs: {
                    title: 'Code',
                    subtitle: 'Je hebt een sms ontvangen op het telefoonnummer waarmee je de afspraak geboekt hebt.'
                }
            },
        ];
    }
}
