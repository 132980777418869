import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormRadioInputComponent } from './form-radio-input.component';
import {FormErrorModule} from "@util/directives/form-error-directive/form-error.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatRadioModule} from "@angular/material/radio";



@NgModule({
    declarations: [
        FormRadioInputComponent
    ],
    exports: [
        FormRadioInputComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormErrorModule,
    FormsModule,
    MatRadioModule
  ]
})
export class FormRadioInputModule { }
