import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseFormInputComponent} from "@components/molecules/forms/base-form-input/base-form-input.component";

@Component({
  selector: 'app-form-datepicker-input',
  templateUrl: './form-datepicker-input.component.html',
  styleUrls: ['./form-datepicker-input.component.scss']
})
export class FormDatepickerInputComponent extends BaseFormInputComponent implements OnInit {

  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
  }

}
