import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import {BasePageModule} from "../base-page/base-page.module";
import {SectionModule} from "@components/molecules/section/section.module";
import {MatListModule} from "@angular/material/list";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormInputsModule} from "@components/molecules/forms/form-inputs/form-inputs.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {BaseLoginStepModule} from "./steps/base-login-step/base-login-step.module";



@NgModule({
  declarations: [
    LoginComponent
  ],
    imports: [
        CommonModule,
        BasePageModule,
        SectionModule,
        MatListModule,
        FontAwesomeModule,
        FormInputsModule,
        BaseButtonModule,
        BaseLoginStepModule
    ]
})
export class LoginModule { }
