import { Component } from '@angular/core';
import {StepService} from "@core/services/step.service";
import {Router} from "@angular/router";
import {CompanyLocationService} from "@core/services/company-location.service";
import {environment} from "../../../../environments/environment";
import {SecurityService} from "@core/services/security.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
  public steps = [
    {name: 'Behandeling'},
    {name: 'Datum & tijd'},
    {name: 'Gegevens invullen'},
    {name: 'Betaling'},
    {name: 'Afspraak bevestigen'}
  ];
  currentStep = this.stepService.currentStepIndex;
  isMakingAppointment = false;
  menuOpen = false;
  isLoggingIn = false;

  public links = [];

  constructor(
      private stepService: StepService,
      public locationService: CompanyLocationService,
      private securityService: SecurityService,
      private router: Router
  ) {
    if(environment.appEnv !== 'production') {
      this.links = [
        {label: 'Overzicht', url: 'dashboard'},
        {label: 'Consulten', url: 'consulten'},
        // {label: 'Medische gegevens', url: 'medische-gegevens'},
        {label: 'Vragenlijsten', url: 'vragenlijsten'},
        {label: 'Mijn profiel', url: 'mijn-profiel'}
      ];
    } else {
      this.links = [
        {label: 'Overzicht', url: 'dashboard'},
        {label: 'Consulten', url: 'consulten'},
        {label: 'Mijn profiel', url: 'mijn-profiel'}
      ];
    }

    this.stepService.$currentStepIndex.subscribe((value) => {
      this.currentStep = value;
    });

    this.isLoggingIn = this.router.url.includes('login');
    this.isMakingAppointment = this.router.url.includes('afspraak-maken');

    this.router.events.subscribe(() => {
      this.isLoggingIn = this.router.url.includes('login');
      this.isMakingAppointment = this.router.url.includes('afspraak-maken');

      setTimeout(() => {
        const topbarHeight = document.querySelector('.topbar').clientHeight;
        document.documentElement.style.setProperty('--topbar-height', `${topbarHeight}px`);
      })

      if (this.router.url.includes('bedankt')) {
        this.stepService.currentStepIndex = 5;
      }
    });
  }

  public logout() {
    this.securityService.logoff();
    this.router.navigate(['/login']);
  }
}
