import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectTreatmentComponent } from './select-treatment.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatDividerModule} from "@angular/material/divider";
import {StepCardModule} from "@components/molecules/step-card/step-card.module";
import {TreatmentItemModule} from "@components/atoms/treatment-item/treatment-item.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
    SelectedLocationCardHeaderModule
} from "@components/atoms/selected-location-card-header/selected-location-card-header.module";
import {AppointmentSummaryModule} from "@components/organisms/appointment-summary/appointment-summary.module";
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {StepCardFooterModule} from "@components/molecules/step-card-footer/step-card-footer.module";



@NgModule({
    declarations: [
        SelectTreatmentComponent
    ],
    exports: [
        SelectTreatmentComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatDividerModule,
        StepCardModule,
        TreatmentItemModule,
        MatProgressSpinnerModule,
        SelectedLocationCardHeaderModule,
        AppointmentSummaryModule,
        BaseButtonModule,
        StepCardFooterModule
    ]
})
export class SelectTreatmentModule { }
