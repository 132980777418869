import {Injectable, Type} from "@angular/core";
import {
    SelectTreatmentComponent, StepComponent
} from "../../pages/create-appointment/steps/select-treatment/select-treatment.component";
import {Observable, Subject} from "rxjs";
import {
    SelectDateTimeComponent
} from "../../pages/create-appointment/steps/select-date-time/select-date-time.component";
import {
    EnterInformationComponent
} from "../../pages/create-appointment/steps/enter-information/enter-information.component";
import {PaymentComponent} from "../../pages/create-appointment/steps/payment/payment.component";
import {ConfirmationComponent} from "../../pages/create-appointment/steps/confirmation/confirmation.component";
import {BaseStepComponent} from "../../pages/create-appointment/steps/base-step/base-step.component";
import {AppointmentService} from "@core/services/appointment.service";
import {ExaminationProtocol} from "@util/types/models";
import {CompanyLocationService} from "@core/services/company-location.service";

export interface Step {
    component: Type<BaseStepComponent>,
    headerTitle: string,
    inputs: { // Required variables for the component
        title: string,
    }
    nextStepFunction?: () => void
    previousStepFunction?: () => void
}


// Make this the main service when AppointmentService/LoginService extend from
@Injectable({
    providedIn: 'root'
})
export class StepService {
    get currentStep(): Step {
        return this.getSteps()[this.currentStepIndex];
    }
    get currentStepIndex(): number {
        return this._currentStepIndex;
    }

    set currentStepIndex(value: number) {
        this._currentStepIndex = value;
        this.$currentStepIndex.next(value);
    }
    private _currentStepIndex = 0;
    public $currentStepIndex: Subject<any> = new Subject<any>();
    public selectedExamType: any;
    public selectedPaymentMethod: any;

    constructor(
        private locationService: CompanyLocationService
    ) {
    }

    public getSteps(): Step[] {
        const onlineAvailable = this.locationService.activeLocation?.onlineAvailable;
        const steps = [
            {
                component: SelectTreatmentComponent,
                headerTitle: 'Behandeling',
                inputs: {
                    title: 'Selecteer een behandeling',
                },
                nextStepFunction: () => {
                    if (onlineAvailable !== true) {
                        this._currentStepIndex++;
                    }
                }
            },
            {
                component: SelectDateTimeComponent,
                headerTitle: 'Datum & tijd',
                inputs: {
                    title: 'Selecteer een datum en tijd',
                }
            },
            {
                component: EnterInformationComponent,
                headerTitle: 'Gegevens invullen',
                inputs: {
                    title: 'Gegevens invullen',
                },
                nextStepFunction: () => {
                    if (onlineAvailable !== true) {
                        this._currentStepIndex++;
                    }
                },
                previousStepFunction: () => {
                    if (onlineAvailable !== true) {
                        this._currentStepIndex--;
                    }
                }
            },
            {
                component: PaymentComponent,
                headerTitle: 'Betaling',
                inputs: {
                    title: onlineAvailable === true ? 'Ga naar betalen' : 'Afspraak bevestigen',
                }
            }
        ];

        return steps;
    }
}
