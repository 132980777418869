import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDevToolsComponent } from './form-dev-tools.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BaseButtonModule} from "@components/atoms/buttons/base-button/base-button.module";
import {MatMenuModule} from "@angular/material/menu";



@NgModule({
  declarations: [
    FormDevToolsComponent
  ],
  exports: [
    FormDevToolsComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    BaseButtonModule,
    MatMenuModule
  ]
})
export class FormDevToolsModule { }
