<div class="flex gap-8 flex-wrap lg:flex-nowrap" *ngIf="locationService.activeLocation">
    <app-step-card class="w-full lg:w-1/2">
        <ng-container header>
            <app-selected-location-card-header></app-selected-location-card-header>
        </ng-container>

        <ng-container body>
            <app-treatment-item
                [treatment]="stepService.selectedExamType"
                [selectedTreatment]="stepService.selectedExamType"
            ></app-treatment-item>

            <app-date-time-picker></app-date-time-picker>
        </ng-container>

        <ng-container footer>
            <app-step-card-footer
                [canProceed]="!!this.appointmentService.selectedDate && !!this.appointmentService.selectedTime"
            ></app-step-card-footer>
        </ng-container>
    </app-step-card>

    <app-appointment-summary
        *ngIf="stepService.selectedExamType"
        class="w-full lg:w-1/2"
    ></app-appointment-summary>
</div>
